import flagDe from 'assets/img/flag-de.png';
import flagEn from 'assets/img/flag-en.png';
import { useLocale, useTranslator } from 'core/translator';
import React, { ComponentType, useEffect } from 'react';
interface Props {}
export const LanguageChooser: ComponentType<Props> = ({}) => {
  const _ = useTranslator();
  const [locale, set] = useLocale();
  useEffect(() => {
    const languageFromUrl = window.location.hash?.includes('en') ? 'en' : 'de';
    set(languageFromUrl);
  }, []);
  return (
    <div id="language-chooser">
      <div className="label">{_('choose_language')}</div>
      <ul>
        <li
          className={locale === 'en' ? 'active' : ''}
          onClick={() => set('en')}
        >
          <img src={flagEn} />
        </li>
        <li
          className={locale === 'de' ? 'active' : ''}
          onClick={() => set('de')}
        >
          <img src={flagDe} />
        </li>
      </ul>
    </div>
  );
};
