import React, { ComponentType } from 'react';
import CurrencyInput from 'react-currency-input';
import { Currency } from '../Currency';
interface Props {
  value: number;
  quantity: number;
  handleChange: (floatValue: number) => void;
}
export const AmountForQuantityInput: ComponentType<Props> = (props) => {
  const { value, handleChange, quantity } = props;

  return (
    <>
      <CurrencyInput
        value={value}
        onChangeEvent={(event: any, maskedValue: any, floatValue: number) => {
          handleChange(floatValue);
        }}
        decimalSeparator=","
        thousandSeparator="."
        suffix=" €"
      />
      <p>
        x {quantity} = Total: <Currency value={quantity * value * 100} />
      </p>
    </>
  );
};
