import React, { ComponentType } from 'react';
import Loader from 'react-loader-spinner';
export const LoaderOverlay: ComponentType = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 300,
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader type="Watch" color="#E88A47" height={100} width={100} />
    </div>
  );
};
