import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { FormattedDate } from 'shared/components/FormattedDate';
interface Props {
  order?: API.BaseOrder;
}
export const OrderStatus: ComponentType<Props> = ({ order }) => {
  const _ = useTranslator();
  if (!order) {
    return <></>;
  }
  return (
    <table className="ui table detail small">
      <tbody>
        {order.kind === 'FrameOrder' && (
          <tr>
            <th>Angefragt:</th>
            <td>
              <FormattedDate value={order?.requestedAt} withTime />
            </td>
          </tr>
        )}
        {order.kind === 'FrameOrder' && (
          <tr>
            <th>Preis angeboten:</th>
            <td>
              <FormattedDate value={order?.offeredAt} withTime />
            </td>
          </tr>
        )}
        {order?.cancelledAt ? (
          <tr>
            <th>Abgebrochen:</th>
            <td>
              <FormattedDate value={order?.cancelledAt} withTime />
            </td>
          </tr>
        ) : (
          <tr>
            <th>Bestellt:</th>
            <td>
              <FormattedDate value={order?.orderedAt} withTime />
            </td>
          </tr>
        )}
        {order?.shippedAt && (
          <tr>
            <th>Versendet:</th>
            <td>
              <FormattedDate value={order?.shippedAt} withTime />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
