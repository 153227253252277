import bgImage from 'assets/img/dashboard-bg.jpg';
import { useAuth } from 'core/auth';
import { createEndpoint, useRemoteList } from 'core/endpoint';
import { useTranslator } from 'core/translator';
import React, { ComponentType, useMemo } from 'react';
import { Page } from 'shared/components/Page';
import { OrdersList } from 'shared/components/order/list';
import { Button } from 'shared/components/ui/Button';
import { SafeArea } from 'shared/components/ui/SafeArea';
import { OrderTabs } from './OrderTabs';
import { useCreateOrderModal } from './create-order-modal.hook';
interface Props {
  status?: API.OrderStatus;
}
export const Dashboard: ComponentType<Props> = (props) => {
  const query = useMemo(() => {
    return {
      status: props.status,
    };
  }, [props.status]);

  const { user } = useAuth();
  const orders = useRemoteList(
    createEndpoint<API.BaseOrder>('customer/orders'),
    query
  );
  const _ = useTranslator();

  const [modal, { open }] = useCreateOrderModal({ userType: user?.type });

  if (!user) {
    return null;
  }

  return (
    <Page
      background={bgImage}
      title={`${_('welcome')}, ${user!.address.firstName}!`}
    >
      {modal}
      <SafeArea>
        <Button label={_('create_order_or_order_request')} onClick={open} />
        <h2>{_('your_orders')}</h2>
        <OrderTabs userType="customer" />

        <OrdersList orders={orders.data} type="customer" />
      </SafeArea>
    </Page>
  );
};
