import React, { ComponentType, ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  small?: boolean;
  label?: string;
  secondaryLabel?: string;
  link?: string;
  path?: string;
  onClick?: () => void;
  enabled?: boolean;
  icon?: ReactElement;
  style?: any;
  className?: string;
  target?: string;
}
export const Button: ComponentType<Props> = ({
  label,
  secondaryLabel,
  onClick,
  link,
  path,
  enabled = true,
  small,
  icon,
  style,
  className = '',
  target,
}) => {
  const cn = useMemo(() => {
    let cn = `ui button ${className}`;
    if (small) {
      cn = `${cn} small`;
    }
    if (icon) {
      cn = `${cn} icon-button`;
    }
    return cn;
  }, [small, icon, className]);

  const inner = useMemo(
    () => (
      <>
        {icon}
        <div className="label">
          {secondaryLabel && (
            <div /* className="secondary" */>{secondaryLabel}</div>
          )}
          {label}
        </div>
      </>
    ),
    [icon, label, secondaryLabel]
  );

  if (path) {
    if (!enabled) {
      return (
        <div style={style} className={`${cn} disabled`}>
          {inner}
        </div>
      );
    }
    return (
      <Link to={path} className={cn}>
        {inner}
      </Link>
    );
  }
  if (link) {
    if (!enabled) {
      return (
        <div style={style} className={`${cn} disabled`}>
          {inner}
        </div>
      );
    }
    return (
      <a style={style} target={target} className={cn} href={link}>
        {inner}
      </a>
    );
  }
  return (
    <button disabled={!enabled} className={cn} onClick={onClick} style={style}>
      {inner}
    </button>
  );
};
