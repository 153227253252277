import React, { ComponentType } from 'react';

interface Props {
  value?: any;
  innerRef?: any;
  name?: string;
}

export const HiddenInputField: ComponentType<Props> = ({
  value,
  innerRef,
  name,
}) => {
  return <input type="hidden" name={name} ref={innerRef} value={value} />;
};
