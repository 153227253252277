import axios, { AxiosRequestConfig } from 'axios';
import { ITEMS_PER_PAGE } from 'core/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';

axios.interceptors.request.use((config: any) => {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers,
  };
  return config;
});

export interface Endpoint<T extends { id?: number }> {
  list(query?: PaginatedQueryParams): Promise<PaginatedItems<T>>;
  get(query?: { [key: string]: any }): Promise<T[]>;
  get(id: number | string, query?: { [key: string]: any }): Promise<T>;
  getSingleResult(query?: { [key: string]: any }): Promise<T>;
  post(data: Omit<T, 'id'>, customUrl?: string): Promise<T>;
  put(
    id: number | undefined,
    data: Omit<T, 'id'>,
    customUrl?: string
  ): Promise<T>;
  custom<T = any>(options: AxiosRequestConfig): Promise<T>;
  patch<B>(
    id: number | string,
    path: string,
    body?: B,
    config?: AxiosRequestConfig
  ): Promise<T>;
  delete(id: number): Promise<T>;
}

const endpoints = new Map<string, Endpoint<any>>();

export function createEndpoint<T extends { id?: number | undefined }>(
  path: string
): Endpoint<T> {
  const url = `${BACKEND_URL}/${path}`;

  if (endpoints.has(path) === false) {
    endpoints.set(
      path,
      Object.freeze({
        async get(
          arg?: number | string | { [key: string]: any },
          params?: { [key: string]: any }
        ) {
          if (typeof arg === 'number' || typeof arg === 'string') {
            return axios
              .get(`${url}/${arg}`, { params })
              .then((response) => response.data);
          }

          return axios
            .get(url, { params: arg })
            .then((response) => response.data);
        },

        async getSingleResult(query?: { [key: string]: any }) {
          return axios
            .get(url, { params: query })
            .then((response) => response.data);
        },

        async list(params?: PaginatedQueryParams) {
          return axios
            .get(`${url}/list`, { params })
            .then((response) => response.data);
        },

        async post(data: T, customUrl?: string) {
          return axios
            .post(`${customUrl ? `${BACKEND_URL}/${customUrl}` : url}`, data)
            .then((response) => response.data);
        },

        async put(id: number | undefined, data: T, customUrl?: string) {
          return axios
            .put(
              `${customUrl ? `${BACKEND_URL}/${customUrl}` : url}/${
                id ? id : ''
              }`,
              data
            )
            .then((response) => response.data);
        },

        async patch<B>(
          id: number | string,
          path: string = '',
          body?: B,
          config: AxiosRequestConfig = {}
        ) {
          return axios
            .patch(`${url}/${id}/${path}`, body, config)
            .then((response) => response.data);
        },

        async delete(id: number) {
          return axios.delete(`${url}/${id}`).then((response) => response.data);
        },

        async custom(config: AxiosRequestConfig) {
          return axios
            .request({ ...config, baseURL: url })
            .then((response) => response.data);
        },
      })
    );
  }

  return endpoints.get(path) as Endpoint<T>;
}

export function useEndpoint<T extends { id?: number }>(
  endpoint: Endpoint<T>
): [Endpoint<T>, boolean] {
  const [loading, setLoading] = useState(false);
  const decorated = useMemo(
    () =>
      Object.freeze({
        async get(
          arg?: number | string | { [key: string]: any },
          query?: { [key: string]: any }
        ) {
          setLoading(true);

          try {
            let response: any;

            if (typeof arg === 'number' || typeof arg === 'string') {
              response = await endpoint.get(arg, query);
            } else {
              response = await endpoint.get(arg);
            }

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async getSingleResult(query?: { [key: string]: any }) {
          setLoading(true);

          try {
            let response: any;

            response = await endpoint.get(query);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async list(params?: PaginatedQueryParams) {
          setLoading(true);

          try {
            let response: any;

            response = await endpoint.list(params);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async post(data: T, customUrl?: string) {
          setLoading(true);

          try {
            const response = await endpoint.post(data, customUrl);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async put(id: number | undefined, data: T, customUrl?: string) {
          setLoading(true);

          try {
            const response = await endpoint.put(id, data, customUrl);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async patch<B>(
          id: number,
          path: string = '',
          body?: B,
          config: AxiosRequestConfig = {}
        ) {
          setLoading(true);

          try {
            const response = await endpoint.patch(id, path, body, config);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async delete(id: number) {
          setLoading(true);

          try {
            const response = await endpoint.delete(id);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },

        async custom(config: AxiosRequestConfig) {
          try {
            const response = await endpoint.custom(config);

            setLoading(false);

            return response;
          } catch (error) {
            setLoading(false);
            throw error;
          }
        },
      }),
    [endpoint]
  );

  return [decorated, loading];
}

export interface RemoteListHook<T> {
  data: T[];
  loading: boolean;
  load: (query?: { [key: string]: any }) => void;
  delete: (entity: T) => void;
  patch: <B>(
    entity: T,
    path: string,
    body?: B,
    config?: AxiosRequestConfig
  ) => Promise<void>;
}

export function useRemoteList<T extends API.BaseEntity>(
  endpoint: Endpoint<T>,
  query?: { [key: string]: any },
  autoload = true
): RemoteListHook<T> {
  const [data, update] = useState<T[]>([]);
  const [api, loading] = useEndpoint<T>(endpoint);

  const load = useCallback(
    async (queryOverride?: { [key: string]: any }) => {
      update(await api.get(queryOverride || query));
    },
    [query, api]
  );

  const deleteFn = useCallback(
    async (entity: T) => {
      await endpoint.delete(entity.id);
      await load();
    },
    [load, api]
  );

  const patch = useCallback(
    async <B>(
      entity: T,
      path: string,
      body?: B,
      config: AxiosRequestConfig = {}
    ) => {
      await endpoint.patch(entity.id, path, body, config);
      return await load();
    },
    [load, api]
  );

  const hook = useMemo<RemoteListHook<T>>(
    () => ({
      data,
      load,
      delete: deleteFn,
      patch,
      loading,
    }),
    [query, data, load, deleteFn, loading, api]
  );

  useEffect(() => {
    if (autoload) {
      load();
    }
  }, [query]);

  return hook;
}

interface PaginatedItems<T> {
  items: T[];
  included: {
    [key: string]: any | any[];
  };
  totalCount: number;
}

interface PaginatedQueryParams {
  take?: number;
  skip?: number;
  include?: string;
  [key: string]: any;
}

export interface RemotePaginatedListHook<T> extends RemoteListHook<T> {
  paginatedData: PaginatedItems<T>;
  currentPage: number;
  itemsPerPage: number;
  setCurrentPage: (page: number) => void;
}

export function useRemotePaginatedList<T extends API.BaseEntity>(
  endpoint: Endpoint<T>,
  query?: PaginatedQueryParams,
  initialItemsPerPage?: number,
  autoload = true
): RemotePaginatedListHook<T> {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    initialItemsPerPage || ITEMS_PER_PAGE
  );
  const [data, update] = useState<PaginatedItems<T>>({
    items: [],
    included: {},
    totalCount: 0,
  });
  const [api, loading] = useEndpoint<T>(endpoint);

  const load = useCallback(
    async (queryOverride?: { [key: string]: any }) => {
      update(
        await api.list({
          ...(queryOverride || query),
          take: itemsPerPage,
          skip: (currentPage - 1) * itemsPerPage,
        })
      );
    },
    [query, api, currentPage, itemsPerPage]
  );

  const deleteFn = useCallback(
    async (entity: API.BaseEntity) => {
      await endpoint.delete(entity.id);
      await load();
    },
    [load, api]
  );

  const patch = useCallback(
    async <B>(
      entity: T,
      path: string,
      body?: B,
      config: AxiosRequestConfig = {}
    ) => {
      await endpoint.patch(entity.id, path, body, config);
      await load();
    },
    [load, api]
  );

  const hook = useMemo<RemotePaginatedListHook<T>>(
    () => ({
      data: [],
      paginatedData: data,
      load,
      delete: deleteFn,
      patch,
      loading,
      currentPage,
      setCurrentPage,
      itemsPerPage,
    }),
    [query, data, load, deleteFn, loading, api, currentPage, setCurrentPage]
  );

  useEffect(() => {
    if (autoload) {
      load({
        ...query,
        take: query ? query.take : undefined,
        skip: (currentPage - 1) * itemsPerPage,
      });
    }
  }, [query, currentPage]);

  return hook;
}

export interface RemoteSingleHook<T extends { id: number }> {
  data?: T;
  loading: boolean;
  load: (id: number | string) => Promise<T>;
  put<Dto = T>(
    id: number | undefined,
    data: Dto,
    customUrl?: string
  ): Promise<T>;
  delete(id: number): Promise<T>;
  post<Dto = T>(data: Dto, customUrl?: string): Promise<T>;
  patch<Dto = T>(id: number | string, path: string, data?: Dto): Promise<T>;
  custom: (options: AxiosRequestConfig) => Promise<T>;
  clear: () => void;
}

export function useRemoteSingle<T extends { id: number }>(
  endpoint: Endpoint<T>,
  initialData?: T
): RemoteSingleHook<T> {
  const [data, update] = useState<T | undefined>(initialData);
  const [api, loading] = useEndpoint(endpoint);

  const load = useCallback(
    async (id: number | string) => {
      const data = await api.get(id);
      update(data);

      return data;
    },
    [api]
  );

  const clear = useCallback(() => {
    update(undefined);
  }, [api]);

  const put = useCallback(
    async (id: number | undefined, data: any, customUrl?: string) => {
      const entity = await api.put(id, data, customUrl);
      update(entity);
      return entity;
    },
    [api]
  );

  const post = useCallback(
    async (data: any, customUrl?: string) => {
      const entity = await api.post(data, customUrl);
      update(entity);
      return entity;
    },
    [api]
  );

  const delete_ = useCallback(
    async (id: number) => {
      const entity = await api.delete(id);
      update(entity);
      return entity;
    },
    [api]
  );

  const patch = useCallback(
    async (id: number | string, path: string, data: any) => {
      const entity = await api.patch(id, path, data);
      update(entity);
      return entity;
    },
    [api]
  );

  const custom = useCallback(
    async (options: AxiosRequestConfig) => {
      const entity = await api.custom(options);
      update(entity);
      return entity;
    },
    [api]
  );

  const hook = useMemo<RemoteSingleHook<T>>(
    () => ({
      data,
      load,
      loading,
      clear,
      put,
      post,
      custom,
      patch,
      delete: delete_,
    }),
    [data, load, clear, delete_, loading]
  );

  return hook;
}
