import React, { ComponentType } from 'react';

interface Props {
  label?: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  error?: string;
  innerRef?: any;
  name?: string;
  disabled?: boolean;
  onFocus?: () => void;
}
export const Checkbox: ComponentType<Props> = ({
  label,
  checked,
  onChange,
  error,
  innerRef,
  name,
  onFocus,
  disabled,
}) => {
  return (
    <div className="ui checkbox">
      <label>
        {label && <span dangerouslySetInnerHTML={{ __html: label }} />}
        <input
          onFocus={onFocus}
          disabled={disabled}
          ref={innerRef}
          name={name}
          checked={checked}
          onChange={(event) => {
            if (onChange) {
              onChange(!checked);
              //if (event.target.value === 'on') {
              //  onChange(true);
              //} else {
              //  onChange(false);
              //}
            }
          }}
          type="checkbox"
        />
      </label>
      {error && <span className="error-message error ui">{error}</span>}
    </div>
  );
};
