import { useMemo } from 'react';

const FACTOR = 60000;
const FIXED_AMOUNT = 40000;
const THRESHOLD = 1000000;
const THRESHOLD_PIPE_INNER_DIAMETER = 300;
const FIXED_AMOUNT_PIPE = 60000;
export const PREPAYMENT_DISCOUNT_RATE = 0.05;

interface Options {
  shape: API.CoatingOrderShape;
  width?: number;
  height?: number;
  length?: number;
  innerDiameter?: number;
  outerDiameter?: number;
  quantity: number;
  withDiscount: boolean;
  country?: string;
}
export function useCoatingOrderPriceCalculator(options: Options) {
  const {
    width,
    height,
    quantity,
    withDiscount,
    country,
    shape,
    length = 0,
    innerDiameter = 0,
    outerDiameter = 0,
  } = options;

  const discount = withDiscount ? PREPAYMENT_DISCOUNT_RATE : 0;

  const area = useMemo(() => {
    if (width === undefined || height === undefined) {
      return 0;
    }
    return width * height;
  }, [width, height]);

  const isFixedAmount = useMemo(() => {
    if (shape === 'Rectangle') {
      return area < THRESHOLD;
    } else if (shape === 'Pipe') {
      return outerDiameter <= THRESHOLD_PIPE_INNER_DIAMETER;
    }
    return false;
  }, [area, shape, outerDiameter]);

  const lateralSurface = useMemo(() => {
    return (
      Math.PI * innerDiameter * 0.001 * length * 0.001 +
      Math.PI * outerDiameter * 0.001 * length * 0.001
    );
  }, [innerDiameter, outerDiameter, length]);

  const singleAmount = useMemo(() => {
    if (shape === 'Rectangle') {
      if (isFixedAmount) {
        const amount = FIXED_AMOUNT;
        return amount - discount * amount;
      }
      const amount = area * 0.000001 * 2 * FACTOR;
      return amount - discount * amount;
    } else if (shape === 'Pipe') {
      if (isFixedAmount) {
        const amount = FIXED_AMOUNT * length * 0.001;
        return amount - discount * amount;
      } else {
        const amount = FIXED_AMOUNT_PIPE * lateralSurface;
        return amount - discount * amount;
      }
    }
    return 0;
  }, [area, isFixedAmount, discount, shape, length, lateralSurface]);

  const totalAmount = useMemo(() => {
    return singleAmount * quantity;
  }, [quantity, singleAmount]);

  const totalAmountGross = useMemo(() => {
    if (country === 'DE') {
      return totalAmount + (TAX_RATE || 0.19) * totalAmount;
    }
    return totalAmount;
  }, [country, totalAmount]);

  return {
    isFixedAmount,
    fixedAmount: FIXED_AMOUNT,
    fixedAmountPipe: FIXED_AMOUNT_PIPE,
    totalAmount,
    singleAmount,
    factor: FACTOR,
    threshold: THRESHOLD,
    discount,
    totalAmountGross,
    thresholdPipeInnerDiameter: THRESHOLD_PIPE_INNER_DIAMETER,
    lateralSurface,
  };
}
