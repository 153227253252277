import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { LanguageChooser } from 'shared/components/LanguageChooser';
import { Logo } from 'shared/components/Logo';
import { Page } from 'shared/components/Page';
import { Button } from 'shared/components/ui/Button';
import { SafeArea } from 'shared/components/ui/SafeArea';
import './home.scss';
interface Props {}
export const Home: ComponentType<Props> = ({}) => {
  const _ = useTranslator();
  return (
    <Page id="home">
      <SafeArea>
        <Logo />
        <Button label={_('i_am_customer')} path="/auth/login" />
        <Button label={_('i_am_producer')} path="/auth/login" />
        <p>{_('you_dont_have_an_account?')}</p>
        <Button
          className="black"
          label={_('register_now')}
          path="/auth/register"
        />
        <LanguageChooser />
      </SafeArea>
    </Page>
  );
};
