import React, { ComponentType } from 'react';

interface Props {
  withoutMargin?: boolean;
}
export const Divider: ComponentType<Props> = ({ withoutMargin }) => {
  return (
    <div className={`ui divider${withoutMargin ? ' without-margin' : ''}`} />
  );
};
