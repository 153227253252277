import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import bgImage from 'assets/img/dashboard-bg.jpg';
import { useAuth } from 'core/auth';
import { createEndpoint, useRemoteList } from 'core/endpoint';
import { useTranslator } from 'core/translator';
import React, { ComponentType, useMemo, useState } from 'react';
import { Currency } from 'shared/components/Currency';
import { Page } from 'shared/components/Page';
import { SafeArea } from 'shared/components/ui/SafeArea';
import { AdministratorOrdersList } from './AdministratorOrdersList';

interface Props {}

export const Dashboard: ComponentType<Props> = ({}) => {
  const _ = useTranslator();
  const { user } = useAuth();
  const [dateRange, setDateRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);
  const query = useMemo(() => {
    return {
      startDate: dateRange[0],
      endDate: dateRange[1],
    };
  }, [dateRange]);
  const orders = useRemoteList(
    createEndpoint<API.BaseOrder>('administrator/orders'),
    query
  );

  const totalAmount = useMemo(() => {
    return orders.data?.reduce((previous, current) => {
      return previous + (current.price || 0) * current.quantity;
    }, 0);
  }, [orders.data]);

  return (
    <Page
      title={`${_('hello')}, ${user!.address.firstName}!`}
      background={bgImage}
    >
      <SafeArea>
        <DateRangePicker
          value={dateRange}
          onChange={(value: any) => {
            if (value) {
              setDateRange(value);
            } else {
              setDateRange([undefined, undefined]);
            }
          }}
        />

        <AdministratorOrdersList orders={orders.data} />
        <table className="ui table order-stats detail">
          <tbody>
            <tr>
              <th>Gesamtwert</th>
              <td className="numeric">
                <Currency value={totalAmount} />
              </td>
            </tr>
          </tbody>
        </table>
      </SafeArea>
    </Page>
  );
};
