import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { ShadowerPositionField } from './ShadowerPositionField';

interface Props {
  label: string;
  disabled?: boolean;
  onFocus?: (key: keyof API.FrameOrderFormFields) => void;
}
export const ShadowerPositionFields: ComponentType<Props> = ({
  label,
  disabled,
  onFocus,
}) => {
  const _ = useTranslator();
  return (
    <>
      <label>{label}</label>
      <ShadowerPositionField onFocus={onFocus} disabled={disabled} side="top" />
      <ShadowerPositionField
        onFocus={onFocus}
        disabled={disabled}
        side="right"
      />
      <ShadowerPositionField
        onFocus={onFocus}
        disabled={disabled}
        side="bottom"
      />
      <ShadowerPositionField
        onFocus={onFocus}
        disabled={disabled}
        side="left"
      />
    </>
  );
};
