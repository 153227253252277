import { yupResolver } from '@hookform/resolvers';
import warningImg from 'assets/img/icon-warning.png';
import { useAuth } from 'core/auth';

import { createEndpoint, useRemoteSingle } from 'core/endpoint';
import { useAlert } from 'core/modal';
import { useLocale, useTranslator } from 'core/translator';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { PayPalButton } from 'react-paypal-button-v2';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedDate } from 'shared/components/FormattedDate';
import { MiniCart } from 'shared/components/MiniCart';
import { Page } from 'shared/components/Page';
import { CoatingOrderEditor } from 'shared/components/order/editor/coating';

import {
  coatingOrderDefaultValues,
  coatingOrderValidationSchema,
} from 'shared/components/order/editor/coating/form';
import { useCoatingOrderPriceCalculator } from 'shared/components/order/editor/coating/interactive-price/calculator.hook';
import { OrderStatus } from 'shared/components/order/list/OrderStatus';
import { CoatingOrderView } from 'shared/components/order/view/coating';
import { Box, Row } from 'shared/components/ui/Box';
import { Button } from 'shared/components/ui/Button';
import { ButtonGroup } from 'shared/components/ui/ButtonGroup';
import { CountrySelectBox } from 'shared/components/ui/CountrySelectBox';
import { FileUpload } from 'shared/components/ui/FileUpload';
import { InputField } from 'shared/components/ui/InputField';
import { SafeArea } from 'shared/components/ui/SafeArea';
import '../index.scss';

export type FormFields = API.CoatingOrderFormFields<
  API.RectangleCoatingOrder & API.PipeCoatingOrder
>;

export const CoatingOrder: ComponentType = () => {
  const { user } = useAuth();
  const [showPaypalButtons, setShowPaypalButtons] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams<{ id: string }>();
  const _ = useTranslator();
  const history = useHistory();

  const order = useRemoteSingle(
    createEndpoint<API.CoatingOrder>('customer/orders')
  );

  const [focus, setFocus] = useState<keyof FormFields | undefined>(undefined);

  const [warnings, setWarnings] = useState<string[]>([]);
  const [locale] = useLocale();
  const [modal, { alarm }] = useAlert();

  const form = useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: id
      ? coatingOrderDefaultValues
      : {
          ...coatingOrderDefaultValues,
          shape: 'Rectangle',
          shippingAddress: user ? { ...user.address } : {},
        },
    resolver: yupResolver(coatingOrderValidationSchema),
  });

  const withPrePayment = form.watch('prePayment');

  const { singleAmount, totalAmountGross } = useCoatingOrderPriceCalculator({
    width: form.watch('frameWidth'),
    height: form.watch('frameHeight'),
    length: form.watch('frameLength'),
    innerDiameter: form.watch('innerDiameter'),
    outerDiameter: form.watch('outerDiameter'),
    shape: form.watch('shape'),
    quantity: form.watch('quantity'),
    withDiscount: withPrePayment,
    country: order.data?.customer?.address.country,
  });

  useEffect(() => {
    if (id) {
      order
        .load(parseInt(id))
        .then((data) => {
          form.reset(data);
        })
        .catch((e) => {
          alert('Keine Berechtigung.');
          history.replace('/customer/dashboard');
        });
    }
  }, [id]);

  const onPay = useCallback(
    async (data: any) => {
      if (order.data) {
        await order.patch(order.data.id, 'pay');

        setShowPaypalButtons(false);
        setShowLoader(false);
      }
    },
    [order.data]
  );

  const onSaveAsDraft = useCallback(
    async (data: any) => {
      if (order.data) {
        data.amount = singleAmount;
        order.put(undefined, data, `customer/orders/${order.data.id}/coating`);
      } else {
        data.amount = singleAmount;
        const updated = await order.post(data, `/customer/orders/coating`);
        history.replace(`/customer/order/coating/${updated.id}`);
      }
    },
    [order.data, singleAmount]
  );

  const onSubmitOrder = useCallback(
    async (data: any) => {
      if (order.data) {
        data.singleAmount = singleAmount;
        await order.put(
          undefined,
          data,
          `customer/orders/${order.data.id}/coating`
        );
        await order.patch(order.data.id, 'directpay');
        window.document.location.reload();
        // setShowLoader(false);
      }
    },
    [order.data, singleAmount]
  );

  const updateOrder = useCallback(() => {
    if (order.data) {
      order.load(order.data.id);
    }
  }, [order.data]);

  const pageTitle = useMemo(() => {
    let title = `${_('order')} – ${_('order_kind.CoatingOrder')}`;
    if (order.data) {
      title = `${title} #${order.data.id}`;
    }
    return title;
  }, [order, locale]);

  const editable = useMemo(() => {
    return (
      !order.data ||
      (order.data &&
        order.data.requestedAt === null &&
        order.data.paypalStatus === 'none')
    );
  }, [order]);

  useEffect(() => {
    if (showPaypalButtons) {
      window.scrollTo(0, window.document.body.scrollHeight);
    }
  }, [showPaypalButtons]);

  if (order.data && order.data.kind !== 'CoatingOrder') {
    return <Page>Error</Page>;
  }

  return (
    <Page
      className="order"
      headerContent={
        <>
          {/* <Button
            target="_blank"
            enabled={!form.formState.isDirty && !!id}
            small
            link={`${BACKEND_URL}/customer/orders/${order.data?.id}/export?lang=${locale}`}
            icon={<img src={printImg} />}
            style={{ height: 40, width: 40 }}
          /> */}

          {warnings.length > 0 && (
            <Button
              onClick={() =>
                alarm(warnings.map((w) => _(w)).join('<br />'), _('warning'))
              }
              style={{ height: 40, width: 40 }}
              icon={<img src={warningImg} />}
            />
          )}
        </>
      }
      subHeaderContent={
        <>
          {modal}
          {editable &&
            (!order.data || order.data?.paypalStatus === 'none') &&
            !showLoader && (
              <ButtonGroup
                actions={[
                  {
                    label: _('save_as_draft'),
                    action: form.handleSubmit(onSaveAsDraft, (e) =>
                      console.log(e)
                    ),
                  },
                  {
                    label: _('order_now'),
                    disabled: !order.data?.id && !withPrePayment,
                    action: async () => {
                      if (withPrePayment) {
                        await form.handleSubmit(onSaveAsDraft)();
                        setShowPaypalButtons(true);
                      } else {
                        form.handleSubmit(onSubmitOrder)();
                      }
                    },
                  },
                ]}
              />
            )}
        </>
      }
      title={pageTitle}
      subTitle={<OrderStatus order={order.data} type="customer" />}
    >
      <FormProvider {...form}>
        <div>
          {order.data?.orderedAt && (
            <Row>
              <Box>
                <MiniCart
                  items={[
                    {
                      quantity: order.data?.quantity,
                      amount: order.data?.price || 0,
                    },
                  ]}
                  withTax={order.data?.customer.address.country === 'DE'}
                />
                <p>
                  {_('ordered_at')}{' '}
                  <FormattedDate value={order.data.orderedAt} withTime />
                </p>
                {order.data?.shippedAt && (
                  <p>
                    {_('shipped_at')}{' '}
                    <FormattedDate value={order.data.shippedAt} withTime />
                  </p>
                )}
              </Box>
            </Row>
          )}
          <Row>
            <Box>
              {editable && (
                <CoatingOrderEditor
                  order={order.data}
                  onFocus={(key: keyof FormFields | undefined) => {
                    setFocus(key);
                  }}
                  editable={true}
                  form={form}
                />
              )}
              {!editable && order.data && (
                <CoatingOrderView order={order.data} userType={'customer'} />
              )}
            </Box>
          </Row>
          {editable && (
            <>
              <SafeArea>
                <h2>{_('images')}</h2>
              </SafeArea>
              <Row columns={3}>
                {order.data && editable && (
                  <>
                    <Box>
                      <FileUpload
                        onDelete={updateOrder}
                        onUploadSuccess={updateOrder}
                        label={_('frame_image')}
                        currentFile={order.data.frameImage}
                        previewUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/file/frameImage`}
                        uploadUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/upload/frameImage`}
                      />
                    </Box>
                    <Box>
                      <FileUpload
                        onDelete={updateOrder}
                        onUploadSuccess={updateOrder}
                        label={_('part_or_cad_image')}
                        currentFile={order.data.partOrCadImage}
                        previewUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/file/partOrCadImage`}
                        uploadUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/upload/partOrCadImage`}
                      />
                    </Box>
                    <Box>
                      <FileUpload
                        onDelete={updateOrder}
                        onUploadSuccess={updateOrder}
                        label={_('contact_image')}
                        currentFile={order.data.contactImage}
                        previewUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/file/contactImage`}
                        uploadUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/upload/contactImage`}
                      />
                    </Box>
                  </>
                )}
                {!order.data && (
                  <Box>
                    <p>{_('save_as_draft_to_upload_images')}</p>
                  </Box>
                )}
              </Row>
            </>
          )}
          {editable && (
            <>
              <SafeArea>
                <h2>{_('shipping_address')}</h2>
              </SafeArea>
              <Box>
                <Controller
                  control={form.control}
                  name="shippingAddress.company"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('company')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.firstName"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('first_name')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.lastName"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('last_name')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.streetAndNumber"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('street_and_number')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.postalCode"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('postal_code')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.city"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('city')}
                    />
                  }
                />

                <Controller
                  control={form.control}
                  name="shippingAddress.country"
                  as={
                    <CountrySelectBox
                      label={_('country')}
                      error={form.errors.shippingAddress?.country?.message}
                      disabled={!editable}
                    />
                  }
                />
              </Box>
            </>
          )}
          {editable && (
            <>
              <Box>
                {showPaypalButtons && (
                  <div className="block-centered">
                    <p>{_('please_choose_payment_type')}</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _(
                          'by_choosing_payment_type_you_will_accept_terms_and_conditions'
                        ) as string,
                      }}
                    />
                    <PayPalButton
                      createOrder={async (data: any, actions: any) => {
                        setShowLoader(true);
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                currency_code: 'EUR',
                                value: totalAmountGross / 100,
                              },
                              description: `GALVAZON® Bestellung: ${order.data?.orderNumber}`,
                              custom_id: order.data?.id,
                            },
                          ],
                        });
                      }}
                      shippingPreference="NO_SHIPPING"
                      onSuccess={onPay}
                      options={{
                        currency: 'EUR',
                        clientId: PAYPAL_CLIENT_ID,
                      }}
                    />
                  </div>
                )}
              </Box>
            </>
          )}
        </div>
      </FormProvider>
    </Page>
  );
};
