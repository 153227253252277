import welcomeImg from 'assets/img/avatar-ciao.png';
import classNames from 'classnames';
import { useAuth } from 'core/auth';
import { useModal } from 'core/modal';
import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { Button } from '../ui/Button';
import styles from './greeter.module.scss';

const Hello: ComponentType = (props) => {
  const { user, loading } = useAuth();
  const _ = useTranslator();

  const welcomeMessageShown =
    localStorage.getItem('welcomeMessageShown') === 'true';

  const [welcomeModal, { close, open }] = useModal(() => (
    <div className={classNames('popup', styles.host)} onClick={() => close()}>
      <img src={welcomeImg} />
      <div className={styles.text}>
        <h1>{_('welcome_message')}</h1>
      </div>
    </div>
  ));

  if (user && !welcomeMessageShown) {
    localStorage.setItem('welcomeMessageShown', 'true');

    open();
  }
  return <>{welcomeModal}</>;
};

interface GoodByeProps {
  onOk: () => void;
  onCancel: () => void;
}
const GoodBye: ComponentType<GoodByeProps> = (props) => {
  const { user, loading } = useAuth();
  const _ = useTranslator();

  return (
    <div className={classNames('popup', styles.host)} onClick={() => close()}>
      <img src={welcomeImg} />
      <div className={styles.text}>
        <h1>Auf wiedersehen!</h1>
        <p>Möchten Sie sich wirklich abmelden?</p>
        <div className="actions">
          <Button
            className="color bg red"
            onClick={props.onOk}
            label={_('ok')}
          />
          <Button onClick={props.onCancel} label={_('cancel')} />
        </div>
      </div>
    </div>
  );
};

export const Greeter = {
  Hello,
  GoodBye,
};
