import { createEndpoint, useEndpoint } from 'core/endpoint';
import { useTranslator } from 'core/translator';
import React, { ComponentType, useCallback } from 'react';
import { ButtonGroup } from 'shared/components/ui/ButtonGroup';

interface Props {
  order: API.BaseOrder;
  onUpdateOrder: () => void;
}

export const AcceptOffer: ComponentType<Props> = ({ order, onUpdateOrder }) => {
  const _ = useTranslator();
  const [endpoint] = useEndpoint(createEndpoint('customer/orders'));
  const onAccept = useCallback(() => {
    endpoint.patch(order.id, 'accept').then(onUpdateOrder);
  }, [order, onUpdateOrder]);

  const onReject = useCallback(() => {
    endpoint.patch(order.id, 'reject').then(onUpdateOrder);
  }, [order, onUpdateOrder]);

  return (
    <>
      <h2>{_('do_you_want_to_accept_the_offer?')}</h2>
      <ButtonGroup
        actions={[
          {
            label: _('binding_order'),
            action: onAccept,
            className: 'ok',
          },
          {
            label: _('reject'),
            action: onReject,
            className: 'cancel',
          },
        ]}
      />
    </>
  );
};
