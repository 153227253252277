import logoImage from 'assets/img/logo.jpg';
import React from 'react';

export const Logo = () => {
  return (
    <a href="/">
      <img className="logo" height="120" src={logoImage} />
    </a>
  );
};
