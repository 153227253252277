import React, { ComponentType } from 'react';

interface Props {
  label?: string;
  placeholder?: string;
  required?: boolean;
  value?: string | undefined;
  onChange?: (value: string) => void;
  style?: any;
  error?: string;
  small?: boolean;
  disabled?: boolean;
}

export const TextArea: ComponentType<Props> = ({
  label,
  value,
  onChange,
  placeholder,
  required,
  style,
  error,
  disabled,
  small,
}) => {
  return (
    <div style={style} className={`ui input-field${small ? ' small' : ''}`}>
      {label && <label>{`${label}${required ? ' *' : ''}`}</label>}
      {error && <span className="ui error-message error">{error}</span>}
      <textarea
        disabled={disabled}
        required={required}
        value={value}
        placeholder={placeholder && `${placeholder}${required ? ' *' : ''}`}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
    </div>
  );
};
