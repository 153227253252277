// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1dhuqwMDKHD-UlpIY84-wX{color:#777}", ""]);
// Exports
exports.locals = {
	"host": "_1dhuqwMDKHD-UlpIY84-wX"
};
module.exports = exports;
