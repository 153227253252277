import { useModal } from 'core/modal';
import { useTranslator } from 'core/translator';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'shared/components/ui/Button';

interface UseCreateOrderModalOptions {
  userType?: API.UserType;
}

export function useCreateOrderModal(options: UseCreateOrderModalOptions) {
  const { userType } = options;
  const _ = useTranslator();
  const history = useHistory();
  return useModal((close) => (
    <div className="popup">
      <div className="alert">
        <h2>{_('create_order_or_order_request')}</h2>
        <p>{_('please_choose_order_kind')}</p>
        <div className="actions same-height">
          <Button
            className="color bg red"
            onClick={() => {
              history.push(`/${userType}/order/frame`);
              close();
            }}
            label={_(`order_kind.FrameOrder`)}
          />
          <Button
            className="color bg red"
            onClick={() => {
              history.push(`/${userType}/order/coating`);
              close();
            }}
            secondaryLabel="Rackstar®"
            label={_(`order_kind.CoatingOrder`)}
          />
          <Button onClick={close} label={_('cancel')} />
        </div>
      </div>
      <div className="close-button" onClick={close} />
    </div>
  ));
}
