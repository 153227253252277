import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { formatCentsCurrency, formatPercentage } from 'shared/utils';
import { useCoatingOrderPriceCalculator } from '../calculator.hook';
import styles from './formula.module.scss';
import { Operand } from './operand.component';

interface RectangleFormulaProps {
  withDiscount: boolean;
  country?: string;
  width: number;
  height: number;
  quantity: number;
}

export const RectangleFormula: ComponentType<RectangleFormulaProps> = (
  props
) => {
  const { withDiscount, country, width, height, quantity } = props;

  const {
    isFixedAmount,
    fixedAmount,
    totalAmount,
    factor,
    threshold,
    discount,
    totalAmountGross,
  } = useCoatingOrderPriceCalculator({
    width,
    height,
    shape: 'Rectangle',
    quantity,
    withDiscount,
    country,
  });
  const _ = useTranslator();
  return (
    <>
      <div className={styles.host}>
        {isFixedAmount && (
          <div className={styles.row}>
            <Operand
              label={_('fixed_amount')}
              value={formatCentsCurrency(fixedAmount, '')}
              unit="€"
            />{' '}
            x <Operand label={_('quantity')} value={quantity} />
            {discount !== 0 && (
              <>
                –{' '}
                <Operand
                  label={_('discount')}
                  value={formatPercentage(discount, false)}
                  unit="%"
                />
              </>
            )}
            ={' '}
            <Operand
              emphasized
              label={_('total_price') + ' (net)'}
              value={formatCentsCurrency(totalAmount, '')}
              unit="€"
            />
            {totalAmount !== totalAmountGross && (
              <>
                ≙{' '}
                <Operand
                  emphasized
                  label={_('total_price') + ' (gross)'}
                  value={formatCentsCurrency(totalAmountGross, '')}
                  unit="€"
                />
              </>
            )}
          </div>
        )}
        {!isFixedAmount && (
          <div className={styles.row}>
            <Operand
              label={_('frame_width')}
              unit="m"
              value={(width * 0.001).toFixed(2).replace('.', ',')}
            />{' '}
            x{' '}
            <Operand
              label={_('frame_height')}
              unit="m"
              value={(height * 0.001).toFixed(2).replace('.', ',')}
            />{' '}
            x <Operand label="Faktor" unit="€" value={2} /> x{' '}
            <Operand value={formatCentsCurrency(factor, '')} /> x{' '}
            <Operand label={_('quantity')} value={quantity} />
            {discount !== 0 && (
              <>
                –{' '}
                <Operand
                  label={_('discount')}
                  value={formatPercentage(discount, false)}
                  unit="%"
                />
              </>
            )}{' '}
            ={' '}
            <Operand
              emphasized
              label={_('total_price') + ' (net)'}
              value={formatCentsCurrency(totalAmount, '')}
              unit="€"
            />
          </div>
        )}
      </div>
      <p
        className="ui hint"
        dangerouslySetInnerHTML={{
          __html: _('fixed_amount_info', {
            values: {
              threshold: `${(threshold * 0.000001)
                .toFixed(0)
                .replace('.', ',')} m<sup>2</sup>`,
              fixedPrice: formatCentsCurrency(fixedAmount),
            },
          }) as string,
        }}
      ></p>
    </>
  );
};
