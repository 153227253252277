import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Checkbox } from 'shared/components/ui/Checkbox';
import { FormRow } from 'shared/components/ui/FormRow';
import { InputField } from 'shared/components/ui/InputField';
import { NumberField } from 'shared/components/ui/NumberField';
import { SelectBox } from 'shared/components/ui/SelectBox';
import { TextArea } from 'shared/components/ui/TextArea';
import { calculateFrameDepth } from 'shared/utils';
import { ShadowerPositionFields } from './shadower-position-fields/ShadowerPositionFields';

interface Props {
  form: UseFormMethods<API.FrameOrderFormFields>;
  order?: API.FrameOrder;
  editable: boolean;
  onFocus?: (key: keyof API.FrameOrderFormFields) => void;
}
export const FrameOrderEditor: ComponentType<Props> = ({
  form,
  order,
  editable,
  onFocus,
}) => {
  const _ = useTranslator();

  return (
    <div className="columns">
      <div className="column">
        <Controller
          control={form.control}
          name="quantity"
          as={
            <NumberField
              errors={form.errors}
              onFocus={() => onFocus && onFocus('quantity')}
              disabled={!editable}
              label={_('quantity_of_frames')}
            />
          }
        />

        <Controller
          control={form.control}
          name="articleNumber"
          as={
            <InputField
              error={
                form.errors.articleNumber
                  ? form.errors.articleNumber.message
                  : undefined
              }
              disabled={!editable}
              label={_('article_number')}
            />
          }
        />
        <Controller
          control={form.control}
          name="articleDescription"
          as={
            <InputField
              error={
                form.errors.articleDescription
                  ? form.errors.articleDescription.message
                  : undefined
              }
              disabled={!editable}
              label={_('article_description')}
            />
          }
        />
        <Controller
          control={form.control}
          name="maxAppliedCurrent"
          as={
            <NumberField
              prepend="A"
              errors={form.errors}
              onFocus={() => onFocus && onFocus('maxAppliedCurrent')}
              disabled={!editable}
              label={_('max_applied_current')}
            />
          }
        />
        <Controller
          control={form.control}
          name="numHangingHooks"
          as={
            <NumberField
              onFocus={() => onFocus && onFocus('numHangingHooks')}
              disabled={!editable}
              label={_('num_hanging_hooks')}
            />
          }
        />
        <Controller
          control={form.control}
          name="distanceHangingHooks"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('distanceHangingHooks')}
              disabled={!editable || form.getValues().numHangingHooks <= 1}
              label={_('distance_hanging_hooks')}
            />
          }
        />

        <Controller
          control={form.control}
          name="platingProcessType"
          as={
            <SelectBox
              onFocus={() => onFocus && onFocus('platingProcessType')}
              disabled={!editable}
              label={_('plating_process_type')}
              choices={[
                {
                  label: _('pop') as string,
                  value: 'pop',
                },
                {
                  label: _('crc') as string,
                  value: 'crc',
                },
                {
                  label: _('ad') as string,
                  value: 'ad',
                },
                {
                  label: _('wr') as string,
                  value: 'wr',
                },
                {
                  label: _('dec_brass') as string,
                  value: 'dec_brass',
                },
                {
                  label: _('dec_zinc') as string,
                  value: 'dec_zinc',
                },
                {
                  label: _('chem_nickel') as string,
                  value: 'chem_nickel',
                },
              ]}
            />
          }
        />
        <Controller
          control={form.control}
          name="frameMaterial"
          as={
            <SelectBox
              onFocus={() => onFocus && onFocus('frameMaterial')}
              disabled={!editable}
              label={_('frame_material')}
              choices={[
                {
                  label: _('aluminium') as string,
                  value: 'aluminium',
                },
                {
                  label: _('brass') as string,
                  value: 'brass',
                },
                {
                  label: _('iron') as string,
                  value: 'iron',
                },
                {
                  label: _('copper') as string,
                  value: 'copper',
                },
              ]}
            />
          }
        />
        <Controller
          control={form.control}
          name="frameHeight"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('frameHeight')}
              disabled={!editable}
              label={_('frame_height')}
            />
          }
        />
        <Controller
          control={form.control}
          name="frameWidth"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('frameWidth')}
              disabled={!editable}
              label={_('frame_width')}
            />
          }
        />
        <NumberField
          disabled={true}
          prepend="mm"
          value={calculateFrameDepth(form.getValues()).toString()}
          label={_('frame_depth_automatic_calculated')}
        />
        <Controller
          control={form.control}
          name="distanceLiquidLevel"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('distanceLiquidLevel')}
              disabled={!editable}
              label={_('distance_liquid_level')}
            />
          }
        />
        <Controller
          control={form.control}
          name="cathodeBarThickness"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('cathodeBarThickness')}
              disabled={!editable}
              label={_('cathode_bar_thickness')}
            />
          }
        />
        <Controller
          control={form.control}
          name="cathodeBarHeight"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('cathodeBarHeight')}
              disabled={!editable}
              label={_('cathode_bar_height')}
            />
          }
        />
        <Controller
          control={form.control}
          name="distanceCathodeBarFirstPart"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('distanceCathodeBarFirstPart')}
              disabled={!editable}
              label={_('distance_cathode_bar_first_part')}
            />
          }
        />
        <Controller
          control={form.control}
          name="jiggingSide"
          render={(props) => (
            <SelectBox
              {...props}
              onChange={(value) => {
                props.onChange(value);
                form.setValue('jiggingOffset', false);
              }}
              onFocus={() => onFocus && onFocus('jiggingSide')}
              disabled={!editable}
              label={_('jigging_side')}
              choices={[
                {
                  label: _('one-side') as string,
                  value: 'one-side',
                },
                {
                  label: _('two-side') as string,
                  value: 'two-side',
                },
              ]}
            />
          )}
        />
        <Controller
          control={form.control}
          name="jiggingOffset"
          label={_('jigging_offset') as string}
          as={
            <Checkbox
              checked={form.getValues().jiggingOffset}
              onFocus={() => onFocus && onFocus('jiggingOffset')}
              disabled={
                !editable || form.getValues().jiggingSide === 'one-side'
              }
            />
          }
        />
      </div>
      <div className="column">
        <Controller
          control={form.control}
          name="numPartColumns"
          as={
            <NumberField
              onFocus={() => onFocus && onFocus('numPartColumns')}
              disabled={!editable}
              label={_('num_part_columns')}
            />
          }
        />
        <Controller
          control={form.control}
          name="numPartRows"
          as={
            <NumberField
              onFocus={() => onFocus && onFocus('numPartRows')}
              disabled={!editable}
              label={_('num_part_rows')}
            />
          }
        />
        <Controller
          control={form.control}
          name="partDistanceHorizontal"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('partDistanceHorizontal')}
              disabled={!editable}
              label={_('part_distance_horizontal')}
            />
          }
        />
        <Controller
          control={form.control}
          name="partDistanceVertical"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('partDistanceVertical')}
              disabled={!editable}
              label={_('part_distance_vertical')}
            />
          }
        />
        <Controller
          control={form.control}
          name="contactingWireDiameter"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('contactingWireDiameter')}
              disabled={!editable}
              label={_('contacting_wire_diameter')}
            />
          }
        />
        <Controller
          control={form.control}
          name="contactingWireLength"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('contactingWireLength')}
              disabled={!editable}
              label={_('contacting_wire_length')}
            />
          }
        />
        <Controller
          control={form.control}
          name="contactingWireAngle"
          as={
            <NumberField
              prepend="˚"
              onFocus={() => onFocus && onFocus('contactingWireAngle')}
              disabled={!editable}
              label={_('contacting_wire_angle')}
            />
          }
        />
        <Controller
          control={form.control}
          name="maxFrameDepth"
          as={
            <NumberField
              prepend="mm"
              onFocus={() => onFocus && onFocus('maxFrameDepth')}
              disabled={!editable}
              label={_('max_frame_depth')}
            />
          }
        />
        <ShadowerPositionFields
          disabled={!editable}
          onFocus={onFocus}
          label={_('shadower_positions') as string}
        />
        <Controller
          control={form.control}
          name="shadowerType"
          as={
            <SelectBox
              onFocus={() => onFocus && onFocus('shadowerType')}
              disabled={!editable}
              label={_('shadower_type')}
              choices={[
                {
                  label: _('passive') as string,
                  value: 'passive',
                },
                {
                  label: _('active') as string,
                  value: 'active',
                },
              ]}
            />
          }
        />
        <FormRow>
          <Controller
            control={form.control}
            name="auxiliaryAnode"
            label={_('auxiliary_anode') as string}
            as={
              <Checkbox
                checked={form.getValues().auxiliaryAnode}
                onFocus={() => onFocus && onFocus('auxiliaryAnode')}
                disabled={!editable}
              />
            }
          />
        </FormRow>
        <FormRow>
          <Controller
            control={form.control}
            name="carrier"
            label={_('carrier') as string}
            as={
              <Checkbox
                checked={form.getValues().carrier}
                onFocus={() => onFocus && onFocus('carrier')}
                disabled={!editable}
              />
            }
          />
        </FormRow>
        <Controller
          control={form.control}
          name="frameColor"
          as={
            <SelectBox
              disabled={!editable}
              onFocus={() => onFocus && onFocus('frameColor')}
              label={_('frame_color')}
              choices={[
                {
                  label: _('green') as string,
                  value: 'green',
                },
                {
                  label: _('blue') as string,
                  value: 'blue',
                },
                {
                  label: _('rackstar') as string,
                  value: 'rackstar',
                },
                {
                  label: _('rackstar_pro') as string,
                  value: 'rackstar_pro',
                },
                {
                  label: _('rackstar_pro_plus') as string,
                  value: 'rackstar_pro_plus',
                },
                {
                  label: _('rackstar_premium') as string,
                  value: 'rackstar_premium',
                },
                {
                  label: _('rackstar_diamond') as string,
                  value: 'rackstar_diamond',
                },
                {
                  label: _('rackstar_infinity') as string,
                  value: 'rackstar_infinity',
                },
              ]}
            />
          }
        />

        <Controller
          control={form.control}
          name="customerComment"
          as={<TextArea disabled={!editable} label={_('comment')} />}
        />
      </div>
    </div>
  );
};
