import classNames from 'classnames';
import React, { ComponentType, ReactNode } from 'react';
import styles from './formula.module.scss';
interface OperandProps {
  label?: string;
  value: number | string;
  unit?: ReactNode;
  emphasized?: boolean;
}

export const Operand: ComponentType<OperandProps> = (props) => {
  return (
    <div
      className={classNames(
        styles.operand,
        props.emphasized && styles.emphasized
      )}
    >
      <div className={styles.label}>{props.label}</div>
      <div className={styles.value}>
        {props.value || 0}
        {props.unit && <div className={styles.unit}>{props.unit}</div>}
      </div>
    </div>
  );
};
