import { useAuth } from 'core/auth';
import { useAlert } from 'core/modal';
import { useTranslator } from 'core/translator';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Page } from 'shared/components/Page';
import { Button } from 'shared/components/ui/Button';
import { InputField } from 'shared/components/ui/InputField';
import { SafeArea } from 'shared/components/ui/SafeArea';

export const ResetPassword = () => {
  const _ = useTranslator();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const { refresh, resetPassword } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [modal, { alarm }] = useAlert();
  const [success, setSuccess] = useState(false);
  const { token } = useParams<{ token: string }>();
  const onReset = useCallback(async () => {
    if (!token) {
      return;
    }
    if (!password) {
      alarm(_('password_may_not_be_empty') as string);
      return;
    }
    if (password !== passwordConfirm) {
      alarm(_('password_does_not_match') as string);
      return;
    }

    try {
      const result = await resetPassword(token, password);
      if (!result) {
        alarm(_('unknown_error') as string);
      }
      setSuccess(true);
    } catch (e) {
      alarm(_('unknown_email') as string, _('error'));
    }
  }, [token, password, passwordConfirm]);

  return (
    <Page title={_('reset_password')} id="reset-password">
      {modal}
      <SafeArea className="centered">
        {!success && (
          <>
            <p>{_('please_choose_a_new_password')}</p>

            <InputField
              placeholder={_('new_password')}
              value={password}
              onKeyPress={(e) => {
                if (e.nativeEvent.keyCode === 13) {
                  onReset();
                }
              }}
              onChange={(value) => setPassword(value)}
            />

            <InputField
              placeholder={_('repeat_password')}
              value={passwordConfirm}
              onKeyPress={(e) => {
                if (e.nativeEvent.keyCode === 13) {
                  onReset();
                }
              }}
              onChange={(value) => setPasswordConfirm(value)}
            />

            <Button label={_('reset_password')} onClick={onReset} />
          </>
        )}
        {success && <p>{_('your_password_has_been_reset')}</p>}
      </SafeArea>
    </Page>
  );
};
