import { useAuth } from 'core/auth';
import { useAlert } from 'core/modal';
import { useTranslator } from 'core/translator';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Page } from 'shared/components/Page';
import { Button } from 'shared/components/ui/Button';
import { InputField } from 'shared/components/ui/InputField';
import { SafeArea } from 'shared/components/ui/SafeArea';

export const ForgotPassword = () => {
  const _ = useTranslator();
  const { user, requestResetPassword } = useAuth();
  const [email, setEmail] = useState('');
  const history = useHistory();
  const location = useLocation();
  const [modal, { alarm }] = useAlert();
  const [success, setSuccess] = useState(false);
  const onReset = useCallback(async () => {
    try {
      const result = await requestResetPassword(email);
      if (!result) {
        alarm(_('unknown_error') as string, _('error'));
      }
      setSuccess(true);
    } catch (e) {
      alarm(_('unknown_email') as string, _('error'));
    }
  }, [email]);

  return (
    <Page title={_('lost_password?')} id="forgot-password">
      {modal}
      <SafeArea className="centered">
        {!success && (
          <>
            <p>{_('please_give_email_to_reset_your_password')}</p>
            <div className="form-row">
              <InputField
                placeholder={_('email')}
                value={email}
                onKeyPress={(e) => {
                  if (e.nativeEvent.keyCode === 13) {
                    onReset();
                  }
                }}
                onChange={(value) => setEmail(value)}
              />
            </div>

            <div className="form-row spaced top">
              <Button label={_('reset_password')} onClick={onReset} />
            </div>
          </>
        )}
        {success && <p>{_('password_reset_requested')}</p>}
      </SafeArea>
    </Page>
  );
};
