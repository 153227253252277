import { useTranslator } from 'core/translator';
import { FormFields } from 'pages/customer/order/coating';
import React, { ComponentType, useMemo, useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { NumberField } from 'shared/components/ui/NumberField';

interface DiameterFieldProps {
  editable?: boolean;
  form: UseFormMethods<FormFields>;
}

export const DiameterField: ComponentType<DiameterFieldProps> = (props) => {
  const { form, editable } = props;

  const [innerDiameter, setInnerDiameter] = useState(
    form.getValues('innerDiameter')
  );
  const [outerDiameter, setOuterDiameter] = useState(
    form.getValues('outerDiameter')
  );

  const valid = useMemo(() => {
    return innerDiameter <= outerDiameter;
  }, [innerDiameter, outerDiameter]);

  const _ = useTranslator();

  return (
    <div>
      <Controller
        name="innerDiameter"
        render={(field) => (
          <NumberField
            onChange={(v) => {
              let value = parseInt(v as string);
              if (isNaN(value)) {
                value = 0;
              }
              setInnerDiameter(value);
            }}
            value={innerDiameter.toString()}
            prepend="mm"
            disabled={!editable}
            onBlur={() => valid && field.onChange(innerDiameter)}
            label={_('inner_diameter')}
          />
        )}
      />

      <Controller
        name="outerDiameter"
        render={(field) => (
          <NumberField
            onChange={(v) => {
              let value = parseInt(v as string);
              if (isNaN(value)) {
                value = 0;
              }
              setOuterDiameter(value);
            }}
            prepend="mm"
            onBlur={() => valid && field.onChange(outerDiameter)}
            disabled={!editable}
            value={outerDiameter.toString()}
            label={_('outer_diameter')}
          />
        )}
      />
      {!valid && <p className="ui error">{_('error_inner_outer_diameter')}</p>}
    </div>
  );
};
