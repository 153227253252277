import React, { ComponentType } from 'react';
import { isoCountries } from 'shared/countries';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  error?: string;
  className?: string;
  small?: boolean;
  innerRef?: any;
  name?: string;
  label?: string;
  required?: string;
  disabled?: boolean;
  onFocus?: () => void;
}
export const CountrySelectBox: ComponentType<Props> = ({
  value,
  onChange,
  error,
  className,
  small,
  innerRef,
  name,
  label,
  required,
  disabled,
  onFocus,
}) => {
  return (
    <div
      className={`ui input-field selectbox${className ? ` ${className}` : ''}${
        small ? ' small' : ''
      }`}
    >
      {label && <label>{`${label}${required ? ' *' : ''}`}</label>}
      <div className="select-box-wrapper">
        <select
          onFocus={onFocus}
          disabled={disabled}
          name={name}
          value={value}
          ref={innerRef}
          onChange={onChange ? (e) => onChange(e.target.value) : undefined}
        >
          {Object.keys(isoCountries).map((key) => (
            <option key={key as string} value={key as string}>
              {isoCountries[key as keyof typeof isoCountries]}
            </option>
          ))}
        </select>
      </div>

      {error && <span className="ui error-message error">{error}</span>}
    </div>
  );
};
