import React, { ComponentType } from 'react';
import { PipeFormula } from './formula/formula.pipe.component';
import { RectangleFormula } from './formula/formula.rectangle.component';
import styles from './interactive-price.module.scss';

interface InteractivePriceProps {
  shape: API.CoatingOrderShape;
  length: number;
  innerDiameter: number;
  outerDiameter: number;
  width: number;
  height: number;
  quantity: number;
  withDiscount: boolean;
  country?: string;
}

export const InteractivePrice: ComponentType<InteractivePriceProps> = (
  props
) => {
  const {
    width,
    height,
    length,
    quantity,
    withDiscount,
    country,
    shape,
    innerDiameter,
    outerDiameter,
  } = props;

  return (
    <div className={styles.host}>
      {shape === 'Rectangle' && (
        <RectangleFormula
          withDiscount={withDiscount}
          country={country}
          width={width}
          height={height}
          quantity={quantity}
        />
      )}
      {shape === 'Pipe' && (
        <PipeFormula
          withDiscount={withDiscount}
          country={country}
          length={length}
          innerDiameter={innerDiameter}
          outerDiameter={outerDiameter}
          quantity={quantity}
        />
      )}
    </div>
  );
};
