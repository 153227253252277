import { PageNotFound } from 'pages/PageNotFound';
import React, { ComponentType } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Dashboard } from './dashboard';
interface Props {}
const Routes: ComponentType<Props> = ({}) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path}>
        <Redirect to={`${match.path}/dashboard`} />
      </Route>
      <Route path={`${match.path}/dashboard`}>
        <Dashboard />
      </Route>
      <Route path={`${match.path}/profile`}>Admin Profile</Route>
      <PageNotFound />
    </Switch>
  );
};

export default Routes;
