import React, { ComponentType } from 'react';
import { Page } from 'shared/components/Page';
import { SafeArea } from 'shared/components/ui/SafeArea';
interface Props {}
export const Imprint: ComponentType<Props> = ({}) => {
  return (
    <Page title="Impressum">
      <SafeArea>Impressum</SafeArea>
    </Page>
  );
};
