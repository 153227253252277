import Axios from 'axios';

import { useTranslator } from 'core/translator';
import React, { ComponentType, useEffect, useState } from 'react';
import { TabBar } from 'shared/components/ui/tab-bar/TabBar';
interface Props {
  userType: API.UserType;
}
export const OrderTabs: ComponentType<Props> = (props) => {
  const _ = useTranslator();
  const { userType } = props;
  const basePath = `/${userType}/dashboard`;
  const [stats, setStats] = useState<{ [key: string]: number }>({});
  useEffect(() => {
    Axios.get(
      `${BACKEND_URL}/${props.userType}/orders/stats`
    ).then((response) => setStats(response.data));
  }, []);
  return (
    <TabBar
      items={[
        {
          label: `${_('status.draft')}`,
          secondaryLabel: stats.draft ?? 0,
          path: `${basePath}/draft`,
        },
        {
          label: `${_('status.requested')}`,
          secondaryLabel: stats.requested ?? 0,
          path: `${basePath}/requested`,
        },
        {
          label: `${_('status.offered')}`,
          secondaryLabel: stats.offered ?? 0,
          path: `${basePath}/offered`,
        },
        {
          label: `${_('status.ordered')}`,
          secondaryLabel: stats.ordered ?? 0,
          path: `${basePath}/ordered`,
        },
        {
          label: `${_('status.shipped')}`,
          secondaryLabel: stats.shipped ?? 0,
          path: `${basePath}/shipped`,
        },
        {
          label: `${_('status.cancelled')}`,
          secondaryLabel: stats.cancelled ?? 0,
          path: `${basePath}/cancelled`,
        },
        {
          label: `${_('all')}`,
          secondaryLabel: stats.all ?? 0,
          path: `${basePath}`,
        },
      ]}
    />
  );
};
