import { useTranslator } from 'core/translator';
import { FormFields } from 'pages/customer/order/coating';
import React, { ComponentType, useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Checkbox } from 'shared/components/ui/Checkbox';
import { HiddenInputField } from 'shared/components/ui/HiddenInputField';
import { InputField } from 'shared/components/ui/InputField';
import { NumberField } from 'shared/components/ui/NumberField';
import { SelectBox } from 'shared/components/ui/SelectBox';
import { TextArea } from 'shared/components/ui/TextArea';
import { formatPercentage } from 'shared/utils';
import { DiameterField } from './diameter-field.component';
import { PREPAYMENT_DISCOUNT_RATE } from './interactive-price/calculator.hook';
import { InteractivePrice } from './interactive-price/interactive-price.component';

interface Props {
  form: UseFormMethods<FormFields>;
  order?: API.CoatingOrder;
  editable: boolean;
  onFocus?: (key: keyof FormFields) => void;
}
export const CoatingOrderEditor: ComponentType<Props> = ({
  form,
  order,
  editable,
  onFocus,
}) => {
  const _ = useTranslator();

  const isRackstar = true; //form.watch('frameColor')?.indexOf('rackstar') > -1;
  const withPrePayment = form.watch('prePayment');

  useEffect(() => {
    if (!isRackstar) {
      form.setValue('prePayment', false);
    }
  }, [isRackstar]);

  const shape = form.watch('shape');

  const innerDiameter = form.watch('innerDiameter');
  const outerDiameter = form.watch('outerDiameter');

  return (
    <div className="columns">
      <div className="column">
        <Controller
          control={form.control}
          name="frameColor"
          as={
            <SelectBox
              disabled={!editable}
              onFocus={() => onFocus && onFocus('frameColor')}
              label={_('coating')}
              choices={[
                {
                  label: _('rackstar') as string,
                  value: 'rackstar',
                },
                {
                  label: _('rackstar_pro') as string,
                  value: 'rackstar_pro',
                },
                {
                  label: _('rackstar_pro_plus') as string,
                  value: 'rackstar_pro_plus',
                },
                {
                  label: _('rackstar_premium') as string,
                  value: 'rackstar_premium',
                },
                {
                  label: _('rackstar_diamond') as string,
                  value: 'rackstar_diamond',
                },
                {
                  label: _('rackstar_infinity') as string,
                  value: 'rackstar_infinity',
                },
              ]}
            />
          }
        />

        <Controller
          control={form.control}
          name="quantity"
          as={
            <NumberField
              errors={form.errors}
              onFocus={() => onFocus && onFocus('quantity')}
              disabled={!editable}
              label={_('quantity_of_frames')}
            />
          }
        />

        <Controller
          control={form.control}
          name="articleNumber"
          as={
            <InputField
              error={
                form.errors.articleNumber
                  ? form.errors.articleNumber.message
                  : undefined
              }
              disabled={!editable}
              label={_('article_number')}
            />
          }
        />
        <Controller
          control={form.control}
          name="articleDescription"
          as={
            <InputField
              error={
                form.errors.articleDescription
                  ? form.errors.articleDescription.message
                  : undefined
              }
              disabled={!editable}
              label={_('article_description')}
            />
          }
        />

        <Controller
          control={form.control}
          name="shape"
          as={
            <SelectBox
              disabled={!editable}
              onFocus={() => onFocus && onFocus('shape')}
              label={_('shape')}
              choices={[
                {
                  label: _('order_shape.Rectangle') as string,
                  value: 'Rectangle',
                },
                {
                  label: _('order_shape.Pipe') as string,
                  value: 'Pipe',
                },
              ]}
            />
          }
        />

        {shape === 'Rectangle' && (
          <>
            <Controller
              control={form.control}
              name="frameHeight"
              as={
                <NumberField
                  prepend="mm"
                  onFocus={() => onFocus && onFocus('frameHeight')}
                  disabled={!editable}
                  label={_('frame_height')}
                />
              }
            />
            <Controller
              control={form.control}
              name="frameWidth"
              as={
                <NumberField
                  prepend="mm"
                  onFocus={() => onFocus && onFocus('frameWidth')}
                  disabled={!editable}
                  label={_('frame_width')}
                />
              }
            />

            <Controller
              control={form.control}
              name="frameLength"
              as={<HiddenInputField />}
            />
            <Controller
              control={form.control}
              name="innerDiameter"
              as={<HiddenInputField />}
            />
            <Controller
              control={form.control}
              name="outerDiameter"
              as={<HiddenInputField />}
            />
          </>
        )}
        {shape === 'Pipe' && (
          <>
            <Controller
              control={form.control}
              name="frameLength"
              as={
                <NumberField
                  prepend="mm"
                  onFocus={() => onFocus && onFocus('frameLength')}
                  disabled={!editable}
                  label={_('frame_length')}
                />
              }
            />
            <DiameterField form={form} editable={editable} />
            <Controller
              control={form.control}
              name="frameWidth"
              as={<HiddenInputField />}
            />
            <Controller
              control={form.control}
              name="frameHeight"
              as={<HiddenInputField />}
            />
          </>
        )}

        <Controller
          control={form.control}
          name="customerComment"
          as={<TextArea disabled={!editable} label={_('comment')} />}
        />
      </div>
      <div className="column">
        {isRackstar && (
          <>
            <h2>{_('prepayment')}</h2>

            <p
              dangerouslySetInnerHTML={{
                __html: _('prepayment_info', {
                  values: {
                    discountRate: formatPercentage(PREPAYMENT_DISCOUNT_RATE),
                  },
                }) as string,
              }}
            />
            <Controller
              control={form.control}
              name="prePayment"
              as={
                <Checkbox
                  label={`${_('prepayment')} via PayPal`}
                  checked={withPrePayment}
                />
              }
            />
          </>
        )}
        {!isRackstar && (
          <Controller
            control={form.control}
            name="prePayment"
            as={<HiddenInputField />}
          />
        )}
        <h2>{_('total_price')}</h2>

        <InteractivePrice
          innerDiameter={innerDiameter}
          outerDiameter={outerDiameter}
          shape={shape}
          withDiscount={withPrePayment}
          width={form.watch('frameWidth')}
          height={form.watch('frameHeight')}
          length={form.watch('frameLength')}
          quantity={form.watch('quantity')}
          country={order?.customer?.address?.country}
        />
      </div>
    </div>
  );
};
