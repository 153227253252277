import React, { ComponentType } from 'react';
interface Props {
  customer?: API.Customer;
}
export const CustomerDescription: ComponentType<Props> = ({ customer }) => {
  if (!customer) {
    return <>Kein Kunde</>;
  }
  return (
    <>
      {[customer.address.firstName, customer.address.lastName].join(' ')}{' '}
      <span className="ui small hint">
        &lt;<a href={`mailto:${customer.email}`}>{customer.email}</a>&gt;
      </span>
    </>
  );
};
