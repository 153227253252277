import { PageNotFound } from 'pages/PageNotFound';
import React, { ComponentType } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { CoatingOrder } from './order/coating';
import { FrameOrder } from './order/frame';
interface Props {}
const Routes: ComponentType<Props> = ({}) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/dashboard/requested`} />
      </Route>
      <Route path={`${match.path}/dashboard/requested`}>
        <Dashboard status="requested" />
      </Route>
      <Route path={`${match.path}/dashboard/offered`}>
        <Dashboard status="offered" />
      </Route>
      <Route path={`${match.path}/dashboard/ordered`}>
        <Dashboard status="ordered" />
      </Route>
      <Route path={`${match.path}/dashboard/shipped`}>
        <Dashboard status="shipped" />
      </Route>
      <Route exact path={`${match.path}/dashboard`}>
        <Dashboard />
      </Route>
      <Route path={`${match.path}/order/frame/:id?`}>
        <FrameOrder />
      </Route>
      <Route path={`${match.path}/order/coating/:id?`}>
        <CoatingOrder />
      </Route>
      <PageNotFound />
    </Switch>
  );
};

export default Routes;
