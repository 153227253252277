import { createEndpoint } from 'core/endpoint';
import { useConfirm } from 'core/modal';
import { useLocale, useTranslator } from 'core/translator';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'shared/components/ui/Button';
import { OrderStatus } from './OrderStatus';
interface Props {
  order: API.BaseOrder;
  keyProp: string | number;
  type: 'customer' | 'producer';
}
export const OrdersListItem: ComponentType<Props> = ({
  order,
  type,
  keyProp,
}) => {
  const _ = useTranslator();
  const [locale] = useLocale();
  const [modal, { confirm }] = useConfirm();
  const history = useHistory();

  const frameOrderEndpoint = createEndpoint<API.FrameOrder>(
    `${type}/orders/frame`
  );
  const orderEndpoint = createEndpoint<API.BaseOrder>(`${type}/orders`);

  const onCancelClick = useCallback(() => {
    confirm(
      [
        {
          label: _('yes') as string,
          callback: async () => {
            await orderEndpoint.patch(order.id, 'cancel');
            history.go(0);
          },
        },
      ],
      _('do_you_want_to_cancel_order {{num}}?', {
        values: { num: order.orderNumber },
      }) as string
    );
  }, [order.id, order.orderNumber]);

  const onRejectClick = useCallback(() => {
    confirm(
      [
        {
          label: _('yes') as string,
          callback: async () => {
            await orderEndpoint.patch(order.id, 'reject');
            history.go(0);
          },
        },
      ],
      _('do_you_want_to_cancel_order {{num}}?', {
        values: { num: order.orderNumber },
      }) as string
    );
  }, [order.id]);

  const onDeleteClick = useCallback(() => {
    confirm(
      [
        {
          label: _('yes') as string,
          callback: async () => {
            await orderEndpoint.patch(order.id, 'delete');
            history.go(0);
          },
        },
      ],
      _('do_you_want_to_delete_order {{num}}?', {
        values: { num: order.orderNumber },
      }) as string
    );
  }, [order.id]);

  const actions = useMemo(() => {
    const actions = [];
    if (type === 'customer') {
      actions.push(
        <Button
          small
          path={`/customer/order/${
            order.kind === 'CoatingOrder' ? 'coating' : 'frame'
          }/${order.id}`}
          label={_('edit')}
        />
      );
      if (order.status === 'draft') {
        actions.push(
          <Button
            small
            className="color bg red"
            onClick={onDeleteClick}
            label={_('delete')}
          />
        );
      }
      if (order.status === 'requested') {
        actions.push(
          <Button
            small
            className="color bg red"
            onClick={onCancelClick}
            label={_('cancel_order')}
          />
        );
      }
      if (order.status === 'offered') {
        actions.push(
          <Button
            small
            className="color bg red"
            onClick={onRejectClick}
            label={_('reject')}
          />
        );
      }
    } else if (type == 'producer') {
      actions.push(
        <Button
          small
          path={`/producer/order/${
            order.kind === 'CoatingOrder' ? 'coating' : 'frame'
          }/${order.id}`}
          label={_('edit')}
        />
      );
      if (order.status === 'requested') {
        actions.push(
          <Button
            small
            className="color bg red"
            onClick={onCancelClick}
            label={_('deny_order')}
          />
        );
      }
    }

    return actions;
  }, [type, order, locale]);
  return (
    <tr key={keyProp}>
      {modal}
      <td>{order.orderNumber}</td>
      <td>{_(`order_kind.${order.kind}`)}</td>
      <td>
        <OrderStatus type={type} order={order} />
      </td>
      <td className="actions">{actions}</td>
    </tr>
  );
};
