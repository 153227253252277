// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".qdf3ic53dUdJRBVcLWeXK{background:#fff;padding:0 !important;display:flex}.qdf3ic53dUdJRBVcLWeXK img{display:block;height:auto;width:300px;margin:40px 40px 0 40px}.qdf3ic53dUdJRBVcLWeXK ._2XKL2lH5UYy7VrHUHxHrf{padding:40px;text-align:left;display:flex;flex-direction:column;justify-content:center}.qdf3ic53dUdJRBVcLWeXK ._2XKL2lH5UYy7VrHUHxHrf h1,.qdf3ic53dUdJRBVcLWeXK ._2XKL2lH5UYy7VrHUHxHrf h2,.qdf3ic53dUdJRBVcLWeXK ._2XKL2lH5UYy7VrHUHxHrf h3,.qdf3ic53dUdJRBVcLWeXK ._2XKL2lH5UYy7VrHUHxHrf h4{font-size:30px}@media screen and (max-width: 840px){.qdf3ic53dUdJRBVcLWeXK{flex-direction:column-reverse}.qdf3ic53dUdJRBVcLWeXK img{margin-top:0;width:180px}}", ""]);
// Exports
exports.locals = {
	"host": "qdf3ic53dUdJRBVcLWeXK",
	"text": "_2XKL2lH5UYy7VrHUHxHrf"
};
module.exports = exports;
