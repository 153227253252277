import { useAuth } from 'core/auth';
import { useTranslator } from 'core/translator';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from 'shared/components/Page';
import { Button } from 'shared/components/ui/Button';
import { SafeArea } from 'shared/components/ui/SafeArea';

export const Verification = () => {
  const { user, verifyAndLogin } = useAuth();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const _ = useTranslator();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (!user) {
      try {
        verifyAndLogin(token)
          .then(() => {
            setMessage(_('verification_successful') as string);
            setSuccess(true);
            setLoading(false);
          })
          .catch((e) => {
            if (e.response.status === 409) {
              setMessage(_('account_already_verified') as string);
            } else {
              setMessage(_('invalid_token') as string);
            }
            setLoading(false);
          });
      } catch (e) {
        setMessage(_('unknown_error') as string);
        setLoading(false);
      }
    }
  }, [user, token]);

  return (
    <Page title={_('verification')}>
      <SafeArea className="centered">
        {user && (
          <>
            <p>{success ? _('verification_successful') : message}</p>
            <Button path="/auth/login" label={_('log_in_now')} />
          </>
        )}

        {!user && (
          <>
            {loading && <>{_('please_wait')}</>}

            {!loading && (
              <>
                <p>{success ? _('verification_successful') : message}</p>
                <Button path="/auth/login" label={_('log_in_now')} />
              </>
            )}
          </>
        )}
      </SafeArea>
    </Page>
  );
};
