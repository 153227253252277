import React, { ComponentType } from 'react';
import { formatCentsCurrency } from 'shared/utils';

interface Props {
  value: number;
  className?: string;
}
export const Currency: ComponentType<Props> = ({ value, className }) => {
  return (
    <span className={`ui currency${className ? ` ${className}` : ''}`}>
      {formatCentsCurrency(value)}
    </span>
  );
};
