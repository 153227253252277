import { AuthProvider } from 'core/auth';
import { TranslationProvider } from 'core/translator';
import 'pages/admin';
import 'pages/customer';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from 'shared/components/App';
import 'shared/components/ui/ui.scss';
import 'shared/global.scss';
// import { register } from './registerServiceWorker';
const domNode = document.getElementById('galvazon-root')!;
ReactDOM.render(
  <AuthProvider>
    <TranslationProvider>
      <Router>
        <App />
      </Router>
    </TranslationProvider>
  </AuthProvider>,
  domNode
);

// register();
