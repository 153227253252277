import {
  createTranslator,
  withDefaultLocale,
  withFallbackLocale,
  withFallbackTranslation,
  withLogger,
  withPlaceholders,
  withPluralizer,
  withTranslations
} from '@translata/core';
import { basename } from 'path';
import React, {
  ComponentType,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

const defaultLocale = localStorage.getItem('locale') || 'de';
const translationContext = require.context('../..', true, /i18n\/.*\.json/);
const translations = translationContext
  .keys()
  .map(path =>
    withTranslations(
      basename(path).replace('.json', ''),
      translationContext(path)
    )
  );

function initializeTranslator(locale: string) {
  return createTranslator(
    withLogger(),
    withFallbackTranslation(id => id),
    ...translations,
    withDefaultLocale(locale),
    withFallbackLocale('en'),
    withPlaceholders(),
    withPluralizer()
  );
}

const initialTranslator = initializeTranslator(defaultLocale);

type TranslatorContextState = [
  typeof initialTranslator,
  string,
  (value: string) => void
];

export const TranslatorContext = createContext<TranslatorContextState>([
  initialTranslator,
  defaultLocale,
  (value: string) => {
    // implemented in provider
  }
]);

export const TranslationProvider: ComponentType = ({ children }) => {
  const [locale, updateLocale] = useState(
    localStorage.getItem('locale') || defaultLocale
  );

  const translator = useMemo(() => initializeTranslator(locale), [locale]);
  const state = useMemo<TranslatorContextState>(
    () => [translator, locale, updateLocale],
    [translator, locale]
  );

  useEffect(() => {
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
    <TranslatorContext.Provider value={state}>
      {children}
    </TranslatorContext.Provider>
  );
};

export function useTranslator(): typeof initialTranslator {
  const [translator] = useContext(TranslatorContext);

  return translator;
}

export function useLocale(): [string, (value: string) => void] {
  const [, locale, set] = useContext(TranslatorContext);

  return [locale, set];
}
