import bgImage from 'assets/img/dashboard-bg.jpg';
import { useAuth } from 'core/auth';
import { createEndpoint } from 'core/endpoint';
import { useSubscription } from 'core/subscription';
import { useTranslator } from 'core/translator';
import React, { ComponentType, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedDate } from 'shared/components/FormattedDate';
import { Page } from 'shared/components/Page';
import { Box, Row } from 'shared/components/ui/Box';
import { Button } from 'shared/components/ui/Button';
import { CountrySelectBox } from 'shared/components/ui/CountrySelectBox';
import { Fail } from 'shared/components/ui/Fail';
import { InputField } from 'shared/components/ui/InputField';
import { OK } from 'shared/components/ui/OK';
import { SafeArea } from 'shared/components/ui/SafeArea';
interface Props {}
export const Profile: ComponentType<Props> = ({}) => {
  const { user, refresh } = useAuth<API.Customer>();
  const { valid } = useSubscription(user);
  const _ = useTranslator();
  const { register, handleSubmit, watch, errors } = useForm<API.Customer>({
    defaultValues: user ? (user as API.Customer) : undefined,
  });
  const profileEndpoint = createEndpoint('customer/me');
  const onSubmit = useCallback(
    async (data: any) => {
      if (user) {
        await profileEndpoint.put(undefined, data);
        refresh();
      }
    },
    [user]
  );

  if (!user) {
    return null;
  }
  return (
    <Page background={bgImage} title={_('my_account')}>
      <SafeArea>
        <Row>
          <Box label={_('contact_information')}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                label={_('company')}
                name="address.company"
                innerRef={register}
              />
              <InputField
                label={_('first_name')}
                name="address.firstName"
                innerRef={register}
              />
              <InputField
                label={_('last_name')}
                name="address.lastName"
                innerRef={register}
              />
              <InputField
                label={_('street_and_number')}
                error={errors.address?.streetAndNumber ? 'Required' : undefined}
                name="address.streetAndNumber"
                innerRef={register}
              />

              <InputField
                small
                label={_('postal_code')}
                error={errors.address?.postalCode ? 'Required' : undefined}
                name="address.postalCode"
                innerRef={register}
              />
              <InputField
                label={_('city')}
                error={errors.address?.city ? 'Required' : undefined}
                name="address.city"
                innerRef={register}
              />
              <CountrySelectBox
                label={_('country')}
                error={errors.address?.country ? 'Required' : undefined}
                name="address.country"
                innerRef={register}
              />
              <InputField
                label={_('vat_number')}
                error={errors.vatNumber ? 'Required' : undefined}
                name="vatNumber"
                innerRef={register}
              />

              <Button label={_('save')} />
            </form>
          </Box>
          <Box label={_('subscription')}>
            {user.subscriptionEnd ? (
              valid ? (
                <>
                  <OK />
                  {_('your_subscription_is_valid_until')}{' '}
                  <FormattedDate value={user.subscriptionEnd} />.
                </>
              ) : (
                <>
                  <Fail />
                  {_('your_subscription_expired_at')}{' '}
                  <FormattedDate value={user.subscriptionEnd} />.
                  <Button label={_('renew_subscription_now')} />
                </>
              )
            ) : (
              _('unlimited_access')
            )}
          </Box>
        </Row>
      </SafeArea>
    </Page>
  );
};
