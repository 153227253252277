import refreshImg from 'assets/img/icon-refresh.png';
import React, { ComponentType, useEffect, useRef } from 'react';
import { Button } from 'shared/components/ui/Button';
import './index.scss';
import { OrderRenderer } from './renderer';

const renderer = new OrderRenderer();
interface Props {
  orderValues: any;
  visible: boolean;
  onUpdateClick?: () => void;
  highlight?: keyof API.FrameOrderFormFields;
  onCloseClick?: () => void;
}

export const OrderPreview: ComponentType<Props> = ({
  orderValues,
  visible,
  onUpdateClick,
  highlight,
  onCloseClick,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      renderer.setCanvas(canvasRef.current);
    }
  }, [canvasRef.current]);

  useEffect(() => {
    if (canvasRef.current && orderValues) {
      renderer.update(orderValues, highlight);
    }
  }, [canvasRef.current, orderValues, highlight]);

  return (
    <div id="order-preview-box">
      <div className={`inner ${visible ? 'visible' : 'hidden'}`}>
        <canvas
          width={1600}
          height={2400}
          style={{ width: 800, height: 1200 }}
          className="order-preview"
          ref={canvasRef}
        />
        {onUpdateClick && (
          <Button icon={<img src={refreshImg} />} onClick={onUpdateClick} />
        )}
        {onCloseClick && (
          <div className="close-button" onClick={onCloseClick} />
        )}
      </div>
    </div>
  );
};
