import { useAuth, useRoleGuard } from 'core/auth';
import { PageNotFound } from 'pages/PageNotFound';
import AdministratorRoutes from 'pages/admin/Routes';
import AuthRoutes from 'pages/auth/Routes';
import CustomerRoutes from 'pages/customer/Routes';
import ProducerRoutes from 'pages/producer/Routes';
import PublicRoutes from 'pages/public/Routes';
import React, { ComponentType } from 'react';
import Loader from 'react-loader-spinner';
import { Route, Switch } from 'react-router-dom';
import { Footer } from './Footer';
import { LogRoute } from './LogRoute';
import { Greeter } from './greeter/greeter.component';
import { Menu } from './menu';

export const App: ComponentType = () => {
  useRoleGuard();
  const { user, loading } = useAuth();

  return (
    <>
      <Greeter.Hello />
      {loading && <Loader />}
      <LogRoute />
      <Switch>
        <Route path="/auth">
          <AuthRoutes />
        </Route>
        {user && (
          <>
            <Menu />
            <div id="main-screen">
              <Switch>
                {user.type === 'administrator' && (
                  <Route path="/administrator">
                    <AdministratorRoutes />
                    <Footer />
                  </Route>
                )}
                {user.type === 'customer' && (
                  <Route path="/customer">
                    <CustomerRoutes />
                    <Footer />
                  </Route>
                )}
                {user.type === 'producer' && (
                  <Route path="/producer">
                    <ProducerRoutes />
                    <Footer />
                  </Route>
                )}

                <Route>
                  <PageNotFound />
                </Route>
              </Switch>
            </div>
          </>
        )}
        <Route path="/">
          <PublicRoutes />
        </Route>

        <PageNotFound />
      </Switch>
    </>
  );
};
