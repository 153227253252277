import { ErrorMessage } from '@hookform/error-message';
import React, { ComponentType } from 'react';

interface Props {
  label?: string;
  placeholder?: string;
  required?: boolean;
  value?: string | undefined;
  onChange?: (value: string | number) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  style?: any;
  errors?: any;
  small?: boolean;
  disabled?: boolean;
  innerRef?: any;
  name?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  prepend?: string;
}

export const NumberField: ComponentType<Props> = ({
  label,
  value,
  onChange,
  onKeyPress,
  placeholder,
  required,
  style,
  errors,
  small,
  disabled,
  innerRef,
  name,
  onFocus,
  prepend,
  onBlur,
}) => {
  return (
    <div style={style} className={`ui input-field${small ? ' small' : ''}`}>
      {label && <label>{`${label}${required ? ' *' : ''}`}</label>}
      {errors && (
        <span className="ui error-message error">
          <ErrorMessage errors={errors} name={name || ''} />
        </span>
      )}
      <div className="input">
        {prepend && <div className="prepend">{prepend}</div>}
        <input
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          name={name}
          ref={innerRef}
          onKeyPress={onKeyPress}
          required={required}
          value={value === undefined || value === null ? 0 : value}
          placeholder={placeholder && `${placeholder}${required ? ' *' : ''}`}
          onChange={
            onChange
              ? (e) => onChange(e.target.value ? parseInt(e.target.value) : '')
              : undefined
          }
        />
      </div>
    </div>
  );
};
