import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { Page } from 'shared/components/Page';
import { SafeArea } from 'shared/components/ui/SafeArea';
interface Props {}
export const PageNotFound: ComponentType<Props> = ({}) => {
  const _ = useTranslator();

  return (
    <Page title={_('page_not_found')}>
      <SafeArea>
        <p
          dangerouslySetInnerHTML={{
            __html: _(
              'the_requested_page_was_not_found_or_you_are_unprivileged'
            ) as string,
          }}
        />
      </SafeArea>
    </Page>
  );
};
