import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { FormattedDate } from 'shared/components/FormattedDate';
interface Props {
  order?: API.BaseOrder;
  type: 'customer' | 'producer';
}
export const OrderStatus: ComponentType<Props> = ({ order, type }) => {
  const _ = useTranslator();
  if (!order) {
    return <>{_('new')}</>;
  }
  if (order.cancelledAt) {
    return (
      <>
        {_('cancelled_at')} <FormattedDate value={order.cancelledAt} withTime />
      </>
    );
  }
  if (order.shippedAt) {
    return (
      <>
        {_('shipped_at')} <FormattedDate value={order.shippedAt} withTime />
      </>
    );
  }
  if (order.orderedAt) {
    return (
      <>
        {_('ordered_at')} <FormattedDate value={order.orderedAt} withTime />
      </>
    );
  }
  if (order.offeredAt) {
    return (
      <>
        {type === 'producer' ? _('offered_at') : _('received_offer_at')}{' '}
        <FormattedDate value={order.offeredAt} withTime />
      </>
    );
  }
  if (order.requestedAt) {
    return (
      <>
        {_('requested_at')} <FormattedDate value={order.requestedAt} withTime />
      </>
    );
  }

  if (order.paypalStatus === 'waiting_for_payment') {
    return <>{_('waiting_for_paypal')}</>;
  }
  return <>{_('draft')} </>;
};
