import * as yup from 'yup';

const shallBeANumber = yup.number().required();

export const baseOrderValidationSchema = yup.object().shape({
  articleDescription: yup.string(),
  articleNumber: yup.string(),

  quantity: yup.number().min(1),
  customerComment: yup.string().nullable(),
  producerComment: yup.string().nullable(),
});

export const baseOrderDefaultValues = {
  articleDescription: '',
  articleNumber: '',
  customerComment: '',
  producerComment: '',
  quantity: 1,
  status: 'draft' as API.OrderStatus,
  paypalStatus: 'none' as API.PaypalStatus,
  shippingAddress: {},
};
