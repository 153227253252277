import React, { ComponentType, useMemo } from 'react';
import { SafeArea } from './SafeArea';

interface BoxProps {
  label?: string;
  className?: string;
}
export const Box: ComponentType<BoxProps> = ({
  children,
  label,
  className,
}) => {
  const cn = useMemo(() => {
    if (className) {
      return `ui box ${className}`;
    }
    return 'ui box';
  }, [className]);
  return (
    <div className={cn}>
      <SafeArea>
        {label && <h3>{label}</h3>}
        {children}
      </SafeArea>
    </div>
  );
};

interface StatBoxProps {
  label?: string;
  value: number;
}
export const StatBox: ComponentType<StatBoxProps> = ({ label, value }) => {
  return (
    <div className="ui stat box">
      {label && <h3>{label}</h3>}
      <div className="value">{value}</div>
    </div>
  );
};

interface RowProps {
  columns?: number;
}

export const Row: ComponentType<RowProps> = ({ children, columns }) => {
  let className = columns && columns === 2 ? 'ui box-row two' : 'ui box-row';
  if (columns && columns > 2) {
    className = 'ui box-row more-than-two';
  }
  return <div className={className}>{children}</div>;
};

export const Grid: ComponentType = ({ children }) => {
  const className = 'ui grid';
  return <div className={className}>{children}</div>;
};
