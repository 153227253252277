import React, { ComponentType } from 'react';
import { Button } from './Button';

export interface ButtonDefinition {
  label?: string;
  action: () => void;
  disabled?: boolean;
  className?: string;
}

interface Props {
  actions: ButtonDefinition[];
}

export const ButtonGroup: ComponentType<Props> = ({ actions }) => {
  return (
    <div className="ui button-group">
      {actions.map((a, i) => (
        <Button
          enabled={!a.disabled}
          className={a.className}
          key={`button-${i}`}
          label={a.label}
          onClick={a.action}
        />
      ))}
    </div>
  );
};
