import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { calculateFrameDepth } from 'shared/utils';
import { Item } from '../Item';
interface Props {
  order: API.FrameOrder;
  userType: API.UserType;
}
export const FrameOrderView: ComponentType<Props> = ({ order, userType }) => {
  const _ = useTranslator();
  return (
    <>
      <h2>{_('order_details')}</h2>
      <table className="ui table detail">
        <tbody>
          <Item label={_('quantity')} value={order.quantity} />
          <Item
            label={_('article_description')}
            value={order.articleDescription}
          />
          <Item label={_('article_number')} value={order.articleNumber} />
          <Item
            label={_('max_applied_current')}
            value={order.maxAppliedCurrent}
            postfix="A"
          />
          <Item
            label={_('plating_process_type')}
            value={_(order.platingProcessType)}
          />
          <Item label={_('frame_material')} value={_(order.frameMaterial)} />
          <Item label={_('num_hanging_hooks')} value={order.numHangingHooks} />
          <Item
            label={_('frame_size')}
            value={
              <>
                {_('frame_height')}: {order.frameHeight} mm
                <br />
                {_('frame_width')}: {order.frameWidth} mm
                <br />
                {_('frame_depth')}: {calculateFrameDepth(order)} mm
              </>
            }
          />
          <Item
            label={_('distance_liquid_level')}
            value={order.distanceHangingHooks}
            postfix="mm"
          />
          <Item
            label={_('cathode_bar_thickness')}
            value={order.cathodeBarThickness}
            postfix="mm"
          />
          <Item
            label={_('cathode_bar_height')}
            value={order.cathodeBarHeight}
            postfix="mm"
          />
          <Item
            label={_('distance_cathode_bar_first_part')}
            value={order.distanceCathodeBarFirstPart}
            postfix="mm"
          />
          <Item label={_('jigging_side')} value={_(order.jiggingSide)} />
          <Item
            label={_('jigging_offset')}
            value={order.jiggingOffset}
            postfix="mm"
          />
          <Item
            label={_('num_parts')}
            value={
              <>
                {order.numPartRows} {_('row(s)')} x {order.numPartColumns}{' '}
                {_('column(s)')}
              </>
            }
          />
          <Item
            label={_('part_distance')}
            value={
              <>
                {_('horizontal')}: {order.partDistanceHorizontal} mm
                <br />
                {_('vertical')}: {order.partDistanceVertical} mm
              </>
            }
          />
          <Item
            label={_('contacting_wire')}
            value={
              <>
                {_('diameter')}: {order.contactingWireDiameter} mm
                <br />
                {_('length')}: {order.contactingWireLength} mm
              </>
            }
          />
          <Item
            label={_('max_frame_depth')}
            value={order.maxFrameDepth}
            postfix="mm"
          />
          <Item
            label={_('shadower_positions')}
            value={
              <>
                {order.shadowerPositionTop > -1 && (
                  <>
                    {_('top')}: {order.shadowerPositionTop} mm
                  </>
                )}
                <br />
                {order.shadowerPositionRight > -1 && (
                  <>
                    {_('right')}: {order.shadowerPositionRight} mm
                  </>
                )}
                <br />
                {order.shadowerPositionBottom > -1 && (
                  <>
                    {_('bottom')}: {order.shadowerPositionBottom} mm
                  </>
                )}
                <br />
                {order.shadowerPositionLeft > -1 && (
                  <>
                    {_('left')}: {order.shadowerPositionLeft} mm
                  </>
                )}
              </>
            }
          />
          {(order.shadowerPositionTop > 0 ||
            order.shadowerPositionLeft > 0 ||
            order.shadowerPositionBottom > 0 ||
            order.shadowerPositionRight > 0) && (
            <Item label={_('shadower_type')} value={_(order.shadowerType)} />
          )}
          <Item label={_('auxiliary_anode')} value={order.auxiliaryAnode} />
          <Item label={_('carrier')} value={order.carrier} />
          <Item label={_('frame_color')} value={_(order.frameColor)} />
          <Item label={_('customer_comment')} value={order.customerComment} />

          <Item
            label={_('shipping_address')}
            value={
              <>
                {order.shippingAddress.company}
                <br />
                {order.shippingAddress.firstName}{' '}
                {order.shippingAddress.lastName}
                <br />
                {order.shippingAddress.streetAndNumber}
                <br />
                {order.shippingAddress.postalCode} {order.shippingAddress.city}
                <br />
                {order.shippingAddress.country}
              </>
            }
          />
          <Item
            label={_('invoice_address')}
            value={
              <>
                {order.customer.address.company}
                <br />
                {order.customer.address.firstName}{' '}
                {order.customer.address.lastName}
                <br />
                {order.customer.address.streetAndNumber}
                <br />
                {order.customer.address.postalCode}{' '}
                {order.customer.address.city}
                <br />
                {order.customer.address.country}
              </>
            }
          />
          {order.frameImage && (
            <Item
              label={_('frame_image')}
              value={
                <img
                  src={`${BACKEND_URL}/${userType}/orders/${order.id}/file/frameImage`}
                />
              }
            />
          )}
          {order.partOrCadImage && (
            <Item
              label={_('part_or_cad_image')}
              value={
                <img
                  src={`${BACKEND_URL}/${userType}/orders/${order.id}/file/partOrCadImage`}
                />
              }
            />
          )}
          {order.contactImage && (
            <Item
              label={_('contact_image')}
              value={
                <img
                  src={`${BACKEND_URL}/${userType}/orders/${order.id}/file/contactImage`}
                />
              }
            />
          )}
        </tbody>
      </table>
      <h2>{_('producer_details')}</h2>
      <table className="ui table detail">
        <Item label={_('producer_comment')} value={order.producerComment} />
        <Item
          label={_('payment_target')}
          value={order.paymentTarget}
          postfix={_('days')}
        />
        <Item
          label={_('delivery_time')}
          value={order.deliveryTime}
          postfix={_('days')}
        />
        <Item
          label={_('offer_validity')}
          value={order.offerValidity}
          postfix={_('days')}
        />
      </table>
    </>
  );
};
