import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { OrdersListItem } from './OrdersListItem';
interface Props {
  orders: API.BaseOrder[];
  type: 'customer' | 'producer';
}
export const OrdersList: ComponentType<Props> = ({ orders, type }) => {
  const _ = useTranslator();
  return (
    <table className="ui table">
      <thead>
        <tr>
          <th>{_('order_number')}</th>
          <th>{_('order_kind')}</th>
          <th>{_('status')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {orders.map((o) => (
          <OrdersListItem keyProp={o.id} order={o} type={type} />
        ))}
      </tbody>
    </table>
  );
};
