import { useMenu } from 'core/menu';
import React, { useCallback, useState } from 'react';
import { pushRotate as SlideMenu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import './index.scss';
export const Menu = () => {
  const [isOpen, toggleOpen] = useState(false);
  const items = useMenu();
  const handleStateChange = useCallback((state) => {
    toggleOpen(state.isOpen);
  }, []);
  return (
    <SlideMenu
      isOpen={isOpen}
      onStateChange={(state) => handleStateChange(state)}
      outerContainerId="galvazon-root"
      pageWrapId="main-screen"
    >
      {items.map((i) => (
        <Link
          key={i.path}
          to={i.path}
          onClick={() => toggleOpen(false)}
          className="menu-item"
        >
          {i.label}
        </Link>
      ))}
    </SlideMenu>
  );
};
