import React, { ComponentType } from 'react';
interface Props {
  producer?: API.Producer;
}
export const ProducerDescription: ComponentType<Props> = ({ producer }) => {
  if (!producer) {
    return <>Kein Produzent</>;
  }
  return (
    <>
      {[producer.address.firstName, producer.address.lastName].join(' ')}{' '}
      <span className="ui small hint">
        &lt;<a href={`mailto:${producer.email}`}>{producer.email}</a>&gt;
      </span>
    </>
  );
};
