import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { FormattedDate } from 'shared/components/FormattedDate';
import { MiniCart } from 'shared/components/MiniCart';
import { Box, Row } from 'shared/components/ui/Box';
import { MakeOffer } from './MakeOffer';
interface Props {
  order: API.BaseOrder;
  makeNewOffer: boolean;
  setMakeNewOffer: (value: boolean) => void;
  onMakeOffer: (
    value: number,
    comment: string,
    paymentTarget: string,
    deliveryTime: string,
    offerValidity: string
  ) => void;
}
export const NotYetOrdered: ComponentType<Props> = ({
  order,
  makeNewOffer,
  setMakeNewOffer,
  onMakeOffer,
}) => {
  const _ = useTranslator();
  return (
    <>
      {(!order.offeredAt || makeNewOffer) && (
        <>
          <Row>
            <Box>
              {!order.offeredAt && <p>{_('not_yet_offered')}</p>}
              {order.offeredAt && (
                <p>
                  {_('you_offered_the_order_for')}
                  <MiniCart
                    items={[
                      { quantity: order.quantity, amount: order.price || 0 },
                    ]}
                    withTax={order.customer.address.country === 'DE'}
                  />
                  am <FormattedDate value={order.offeredAt} withTime />
                </p>
              )}
            </Box>
          </Row>
          <MakeOffer
            onSubmit={onMakeOffer}
            order={order}
            onCancel={() => setMakeNewOffer(false)}
          />
        </>
      )}
      {order.offeredAt && !makeNewOffer && (
        <>
          <Row>
            <Box>
              <p>
                {_('you_offered_the_order_for')}{' '}
                <MiniCart
                  items={[
                    { quantity: order.quantity, amount: order.price || 0 },
                  ]}
                  withTax={order.customer.address.country === 'DE'}
                />
                am <FormattedDate value={order.offeredAt} withTime />
              </p>
              <p>
                <a href="#" onClick={() => setMakeNewOffer(true)}>
                  {_('correct_offer')}
                </a>
              </p>
            </Box>
          </Row>
        </>
      )}
    </>
  );
};
