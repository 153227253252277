import * as yup from 'yup';

const shallBeANumber = yup.number().required().integer();
const canBeANumber = yup.number().optional().positive().integer();

export const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().min(5).required(),
  acceptPrivacy: yup
    .boolean()
    .required()
    .oneOf([true], 'Privacy conditions have to be accepted.'),
  acceptTermsAndConditions: yup
    .boolean()
    .required()
    .oneOf([true], 'Terms and condition have to be accepted.'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match'),
});

/* export const defaultValues: API.Customer = {
  
}; */
