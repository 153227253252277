import { useTranslator } from 'core/translator';
import React, { ComponentType, useMemo } from 'react';
import { Currency } from './Currency';
interface Props {
  items: { quantity: number; amount: number }[];
  withTax: boolean;
}
export const MiniCart: ComponentType<Props> = (props) => {
  const { items, withTax } = props;
  const _ = useTranslator();
  const total = useMemo(() => {
    return items.reduce((previous, current) => {
      return previous + current.quantity * current.amount;
    }, 0);
  }, props.items);
  return (
    <table className="mini-cart">
      <tbody>
        {items.map((i, index) => (
          <tr key={`item-${index}`}>
            <th>{i.quantity} x</th>
            <td className="numeric">
              <Currency value={i.amount} />
            </td>
          </tr>
        ))}
        <tr className="total">
          <td>{_('total')}</td>
          <td className="numeric">
            <Currency value={total} />
          </td>
        </tr>
        {withTax && (
          <tr className="total">
            <td>{_('totalgross')}</td>
            <td className="numeric">
              <Currency value={total + total * TAX_RATE} />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
