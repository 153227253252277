import axios from 'axios';
import { useTranslator } from 'core/translator';
import React, { ComponentType, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { bytesToHuman } from 'shared/utils';
import { Button } from './Button';

interface Props {
  onUploadSuccess?: () => void;

  onUploadError?: (message: string) => void;
  onDelete?: () => void;
  currentFile?: API.FileEntity | null;
  maxSize?: number;
  uploadUrl: string;
  previewUrl?: string;
  accept?: string | string[];
  label?: string;
}

export const FileUpload: ComponentType<Props> = ({
  onUploadSuccess,
  onUploadError,
  onDelete,
  maxSize,
  currentFile,
  uploadUrl,
  previewUrl,
  accept,
  label,
}) => {
  const _ = useTranslator();
  const [isChange, setChange] = useState(false);
  const onDeleteClick = useCallback(async () => {
    try {
      await axios.delete(uploadUrl);
      if (onDelete) {
        onDelete();
      }
    } catch (e) {
      console.error(e);
    }
  }, [onDelete]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        if (onUploadError) {
          onUploadError('invalid_files');
        }
      } else {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        try {
          await axios.post<string>(uploadUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          if (onUploadSuccess) {
            onUploadSuccess();
          }
        } catch (e) {
          if (onUploadError) {
            onUploadError((e as any).response.data.message);
          }
        }
      }
    },
    [onUploadSuccess, onUploadError]
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple: false,
    maxSize,
    accept: accept,
  });

  return (
    <div className="ui file-upload">
      {label && <label>{label}</label>}
      {currentFile && (
        <>
          <div className="preview">
            <img src={`${previewUrl}?nocache=${Date.now()}}`} />
            {currentFile.name}
          </div>

          {!isChange && (
            <>
              <Button onClick={onDeleteClick} label={_('delete')} />
              <Button onClick={() => setChange(true)} label={_('change')} />
            </>
          )}
        </>
      )}
      {(!currentFile || isChange) && (
        <>
          <div {...getRootProps()} className="ui drop-area">
            <input {...getInputProps()} capture />
            {isDragReject && (
              <>
                {maxSize && (
                  <p className="ui hint error-message error">
                    ({_('max')} {bytesToHuman(maxSize)})
                  </p>
                )}
              </>
            )}
            {isDragActive ? (
              <p>{_('and_release')}</p>
            ) : (
              <>
                <p>{_('drop_files_here')}</p>
                {maxSize && (
                  <p className="ui hint">
                    ({_('max')} {bytesToHuman(maxSize)})
                  </p>
                )}
              </>
            )}
          </div>
          {isChange && (
            <Button label={_('cancel')} onClick={() => setChange(false)} />
          )}
        </>
      )}
    </div>
  );
};
