import { useMemo } from 'react';
import { useAuth } from './auth';
import { useLocale, useTranslator } from './translator';

interface MenuItem {
  path: string;
  label: string;
}
export function useMenu(): MenuItem[] {
  const { user } = useAuth();
  const _ = useTranslator();
  const [locale] = useLocale();

  const items = useMemo(() => {
    if (!user) {
      return [];
    }
    const items: MenuItem[] = [
      {
        path: `/${user.type}`,
        label: _('orders') as string,
      },
    ];
    if (user.type === 'customer') {
      items.push({
        path: `/${user!.type}/profile`,
        label: _('my_account') as string,
      });
    }
    items.push({
      path: '/auth/logout',
      label: _('logout') as string,
    });

    return items;
  }, [user?.type, locale]);

  return items;
}
