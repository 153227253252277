import bgImage from 'assets/img/dashboard-bg.jpg';
import { useAuth } from 'core/auth';
import { createEndpoint, useRemoteList } from 'core/endpoint';
import { useTranslator } from 'core/translator';
import React, { ComponentType, useMemo } from 'react';
import { Page } from 'shared/components/Page';
import { OrdersList } from 'shared/components/order/list';
import { SafeArea } from 'shared/components/ui/SafeArea';
import { OrderTabs } from './OrderTabs';

interface Props {
  status?: API.OrderStatus;
}
export const Dashboard: ComponentType<Props> = (props) => {
  const { user } = useAuth();
  const query = useMemo(() => {
    return { status: props.status };
  }, [props.status]);
  const orders = useRemoteList(
    createEndpoint<API.BaseOrder>('producer/orders'),
    query
  );
  const _ = useTranslator();
  if (!user) {
    return null;
  }
  return (
    <Page
      background={bgImage}
      title={`${_('welcome')}, ${user!.address.firstName}!`}
      loading={orders.loading}
    >
      <SafeArea>
        <h2>{_('your_orders')}</h2>
        <OrderTabs userType="producer" />
        <OrdersList orders={orders.data} type="producer" />
      </SafeArea>
    </Page>
  );
};
