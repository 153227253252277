import { useAuth } from 'core/auth';
import { format } from 'date-fns';
import React from 'react';
import { version } from '../../../package.json';
import { LanguageChooser } from './LanguageChooser';
import { Divider } from './ui/Divider';
import { SafeArea } from './ui/SafeArea';

export const Footer = () => {
  const { user } = useAuth();
  return (
    <div id="footer">
      <Divider />
      <SafeArea>
        {user && user.type !== 'administrator' ? <LanguageChooser /> : <div />}
        <div className="version-copyright">
          GALVAZON® App Version {version}
          <br />
          <a href="https://galvatore.com" target="_blank">
            www.galvatore.com
          </a>
          <div className="copyright">
            Galvatore Plating & Equipment &copy; {format(new Date(), 'Y')}
          </div>
        </div>
      </SafeArea>
    </div>
  );
};
