import { parseISO } from 'date-fns';
import React, { ComponentType } from 'react';

interface Props {
  value?: string;
  withTime?: boolean;
}
export const FormattedDate: ComponentType<Props> = ({ value, withTime }) => {
  const date = parseISO(value ? value : '0000-00-00');
  return <>{withTime ? date.toLocaleString() : date.toLocaleDateString()}</>;
};
