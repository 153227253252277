import { format, parseISO } from 'date-fns';

export function validateEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function bytesToHuman(bytes: number, si = true) {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

export function describerUser(user: API.User) {
  return [user.address.firstName, user.address.lastName].join(' ').trim();
}

export function formatDate(isoDateString: string) {
  return format(parseISO(isoDateString), 'dd.MM.yyyy');
}

export function formatCentsCurrency(amount: number, unit = '€') {
  return `${(amount / 100)
    .toFixed(2)
    .replace('.', ',')
    .replace(',00', ',-')} ${unit}`.trim();
}

export function formatPercentage(value: number, withPercentSign = true) {
  return `${(value * 100).toFixed(0)} ${withPercentSign ? '%' : ''}`.trim();
}

export function calculateFrameDepth(order: API.FrameOrderFormFields) {
  if (order.jiggingSide === 'one-side') {
    return order.contactingWireLength;
  }
  return order.contactingWireLength * 2;
}
