import { ErrorMessage } from '@hookform/error-message';
import { useTranslator } from 'core/translator';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from 'shared/components/ui/Checkbox';
import { FormRow } from 'shared/components/ui/FormRow';
import { NumberField } from 'shared/components/ui/NumberField';
interface Props {
  side: 'left' | 'right' | 'bottom' | 'top';

  disabled?: boolean;
  onFocus?: (key: keyof API.FrameOrderFormFields) => void;
}
export const ShadowerPositionField: ComponentType<Props> = ({
  side,
  disabled,
  onFocus,
}) => {
  const _ = useTranslator();

  const formContext = useFormContext();

  const key = useMemo(() => {
    if (side === 'top') {
      return 'shadowerPositionTop';
    } else if (side === 'right') {
      return 'shadowerPositionRight';
    } else if (side === 'bottom') {
      return 'shadowerPositionBottom';
    }
    return 'shadowerPositionLeft';
  }, [side]);

  const value = formContext.getValues()[key];

  const onFocusCallback = useCallback(() => {
    if (!onFocus) {
      return;
    }
    onFocus(key);
  }, [key, onFocus]);

  return (
    <>
      {formContext.errors && (
        <span className="ui error-message error">
          <ErrorMessage errors={formContext.errors} name={key} />
        </span>
      )}
      <FormRow>
        <Checkbox
          disabled={disabled}
          onFocus={onFocusCallback}
          onChange={(v) => {
            if (!v) {
              formContext.setValue!(key, -1);
            } else {
              formContext.setValue(key, 0);
            }
          }}
          checked={value > -1}
          label={_(side) as string}
        />

        <Controller
          name={key}
          as={
            <NumberField
              prepend="mm"
              style={{ visibility: value > -1 ? 'visible' : 'hidden' }}
              onFocus={onFocusCallback}
            />
          }
        />
      </FormRow>
    </>
  );
};
