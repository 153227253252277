import { yupResolver } from '@hookform/resolvers';
import planImg from 'assets/img/icon-plan.png';
import printImg from 'assets/img/icon-print.png';
import warningImg from 'assets/img/icon-warning.png';
import { useAuth } from 'core/auth';

import { createEndpoint, useRemoteSingle } from 'core/endpoint';
import { useAlert } from 'core/modal';
import { useLocale, useTranslator } from 'core/translator';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { PayPalButton } from 'react-paypal-button-v2';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedDate } from 'shared/components/FormattedDate';
import { MiniCart } from 'shared/components/MiniCart';
import { Page } from 'shared/components/Page';
import { FrameOrderEditor } from 'shared/components/order/editor/frame';

import {
  frameOrderDefaultValues,
  frameOrderValidationSchema,
} from 'shared/components/order/editor/frame/form';
import { OrderStatus } from 'shared/components/order/list/OrderStatus';
import { OrderPreview } from 'shared/components/order/preview';
import { FrameOrderView } from 'shared/components/order/view/frame';
import { Box, Row } from 'shared/components/ui/Box';
import { Button } from 'shared/components/ui/Button';
import { ButtonGroup } from 'shared/components/ui/ButtonGroup';
import { CountrySelectBox } from 'shared/components/ui/CountrySelectBox';
import { FileUpload } from 'shared/components/ui/FileUpload';
import { InputField } from 'shared/components/ui/InputField';
import { SafeArea } from 'shared/components/ui/SafeArea';
import { AcceptOffer } from '../AcceptOffer';
import '../index.scss';

export const FrameOrder: ComponentType = () => {
  const { user } = useAuth();
  const [showPaypalButtons, setShowPaypalButtons] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams<{ id: string }>();
  const _ = useTranslator();
  const history = useHistory();
  const [previewVisible, setPreviewVisible] = useState(false);
  const order = useRemoteSingle(
    createEndpoint<API.FrameOrder>('customer/orders')
  );

  const [focus, setFocus] = useState<
    keyof API.FrameOrderFormFields | undefined
  >(undefined);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [locale] = useLocale();
  const [modal, { alarm }] = useAlert();

  const form = useForm<API.FrameOrderFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: id
      ? frameOrderDefaultValues
      : {
          ...frameOrderDefaultValues,
          shippingAddress: user ? { ...user.address } : {},
        },
    resolver: yupResolver(frameOrderValidationSchema),
  });
  form.watch();

  useEffect(() => {
    if (id) {
      order
        .load(parseInt(id))
        .then((data) => {
          form.reset(data);
        })
        .catch((e) => {
          alert('Keine Berechtigung.');
          history.replace('/customer/dashboard');
        });
    }
  }, [id]);

  const onPay = useCallback(
    async (data: any) => {
      if (order.data) {
        await order.patch(order.data.id, 'pay');
        setShowPaypalButtons(false);
        setShowLoader(false);
      }
    },
    [order.data]
  );

  const onSaveAsDraft = useCallback(
    async (data: any) => {
      if (order.data) {
        order.put(undefined, data, `customer/orders/${order.data.id}/frame`);
      } else {
        const updated = await order.post(data, `/customer/orders/frame`);
        history.replace(`/customer/order/frame/${updated.id}`);
      }
    },
    [order.data]
  );

  const updateOrder = useCallback(() => {
    if (order.data) {
      order.load(order.data.id);
    }
  }, [order.data]);

  const pageTitle = useMemo(() => {
    let title = `${_('order')} – ${_('order_kind.FrameOrder')}`;
    if (order.data) {
      title = `${title} #${order.data.id}`;
    }
    return title;
  }, [order, locale]);

  const editable = useMemo(() => {
    return (
      !order.data ||
      (order.data &&
        order.data.requestedAt === null &&
        order.data.paypalStatus === 'none')
    );
  }, [order]);

  const orderAmount = useMemo(() => {
    if (order.data?.customer?.address.country === 'DE') {
      return 1 + (TAX_RATE || 0.19) * 1;
    }
    return 1;
  }, [order.data?.customer?.address.country]);

  useEffect(() => {
    if (order.data) {
      const w = [];
      if (
        order.data.partDistanceHorizontal * order.data.numPartRows >
        order.data.frameWidth
      ) {
        w.push('frame_width_too_small');
      }
      if (
        order.data.partDistanceVertical * order.data.numPartColumns >
        order.data.frameHeight -
          order.data.cathodeBarHeight -
          order.data.distanceLiquidLevel
      ) {
        w.push('frame_height_too_small');
      }
      setWarnings(w);
    }
  }, [
    order.data?.numPartColumns,
    order.data?.numPartRows,
    order.data?.partDistanceHorizontal,
    order.data?.partDistanceVertical,
    order.data?.frameHeight,
    order.data?.frameWidth,
  ]);

  useEffect(() => {
    if (showPaypalButtons) {
      window.scrollTo(0, window.document.body.scrollHeight);
    }
  }, [showPaypalButtons]);

  return (
    <Page
      className="order"
      headerContent={
        <>
          <Button
            onClick={() => setPreviewVisible(!previewVisible)}
            style={{ height: 40, width: 40 }}
            icon={<img src={planImg} />}
          />

          <Button
            target="_blank"
            enabled={!form.formState.isDirty && !!id}
            small
            link={`${BACKEND_URL}/customer/orders/${order.data?.id}/export?lang=${locale}`}
            icon={<img src={printImg} />}
            style={{ height: 40, width: 40 }}
          />

          {warnings.length > 0 && (
            <Button
              onClick={() =>
                alarm(warnings.map((w) => _(w)).join('<br />'), _('warning'))
              }
              style={{ height: 40, width: 40 }}
              icon={<img src={warningImg} />}
            />
          )}
        </>
      }
      subHeaderContent={
        <>
          {modal}
          {editable &&
            (!order.data || order.data?.paypalStatus === 'none') &&
            !showLoader && (
              <ButtonGroup
                actions={[
                  {
                    label: _('save_as_draft'),
                    action: form.handleSubmit(onSaveAsDraft),
                  },
                  {
                    label: _('request_now'),
                    action: async () => {
                      await form.handleSubmit(onSaveAsDraft)();
                      setShowPaypalButtons(true);
                    },
                  },
                ]}
              />
            )}
        </>
      }
      title={pageTitle}
      subTitle={<OrderStatus order={order.data} type="customer" />}
    >
      {previewVisible && (
        <OrderPreview
          onCloseClick={() => setPreviewVisible(false)}
          highlight={focus}
          /* onUpdateClick={() => form.getValues()} */
          visible={true}
          orderValues={form.getValues()}
        />
      )}

      <FormProvider {...form}>
        <div
          style={{
            visibility: previewVisible ? 'hidden' : 'visible',
            height: previewVisible ? 0 : undefined,
          }}
        >
          {!order.data?.requestedAt &&
            !order.data?.offeredAt &&
            order.data?.paypalStatus === 'waiting_for_payment' && (
              <Row>
                <Box>
                  <p>{_('you_requested_an_order')}</p>
                  <p>{_('you_will_be_notified_when_producer_offered')}</p>
                </Box>
              </Row>
            )}
          {order.data?.requestedAt && !order.data?.offeredAt && (
            <Row>
              <Box>
                <p>{_('you_requested_an_order')}</p>
                <p>{_('you_will_be_notified_when_producer_offered')}</p>
              </Box>
            </Row>
          )}
          {order.data?.offeredAt &&
            !order.data?.orderedAt &&
            !order.data?.cancelledAt && (
              <Row>
                <Box>
                  <p>{_('you_received_an_offer')}</p>
                  <p className="ui notice">{_('all_prices_exwork')}</p>
                  <MiniCart
                    items={[
                      {
                        quantity: order.data?.quantity,
                        amount: order.data?.price || 0,
                      },
                    ]}
                    withTax={order.data?.customer.address.country === 'DE'}
                  />
                  <p>
                    {_('received_at')}{' '}
                    <FormattedDate value={order.data.offeredAt} withTime />
                  </p>
                </Box>
                <Box>
                  <AcceptOffer order={order.data} onUpdateOrder={updateOrder} />
                </Box>
              </Row>
            )}
          {order.data?.orderedAt && (
            <Row>
              <Box>
                <MiniCart
                  items={[
                    {
                      quantity: order.data?.quantity,
                      amount: order.data?.price || 0,
                    },
                  ]}
                  withTax={order.data?.customer.address.country === 'DE'}
                />
                <p>
                  {_('ordered_at')}{' '}
                  <FormattedDate value={order.data.orderedAt} withTime />
                </p>
                {order.data?.shippedAt && (
                  <p>
                    {_('shipped_at')}{' '}
                    <FormattedDate value={order.data.shippedAt} withTime />
                  </p>
                )}
              </Box>
            </Row>
          )}
          <Row>
            <Box>
              {editable && (
                <FrameOrderEditor
                  order={order.data}
                  onFocus={(key) => {
                    setFocus(key);
                  }}
                  editable={true}
                  form={form}
                />
              )}
              {!editable && order.data && (
                <FrameOrderView order={order.data} userType={'customer'} />
              )}
            </Box>
          </Row>
          {editable && (
            <>
              <SafeArea>
                <h2>{_('images')}</h2>
              </SafeArea>
              <Row columns={3}>
                {order.data && editable && (
                  <>
                    <Box>
                      <FileUpload
                        onDelete={updateOrder}
                        onUploadSuccess={updateOrder}
                        label={_('frame_image')}
                        currentFile={order.data.frameImage}
                        previewUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/file/frameImage`}
                        uploadUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/upload/frameImage`}
                      />
                    </Box>
                    <Box>
                      <FileUpload
                        onDelete={updateOrder}
                        onUploadSuccess={updateOrder}
                        label={_('part_or_cad_image')}
                        currentFile={order.data.partOrCadImage}
                        previewUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/file/partOrCadImage`}
                        uploadUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/upload/partOrCadImage`}
                      />
                    </Box>
                    <Box>
                      <FileUpload
                        onDelete={updateOrder}
                        onUploadSuccess={updateOrder}
                        label={_('contact_image')}
                        currentFile={order.data.contactImage}
                        previewUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/file/contactImage`}
                        uploadUrl={`${BACKEND_URL}/customer/orders/${order.data.id}/upload/contactImage`}
                      />
                    </Box>
                  </>
                )}
                {!order.data && (
                  <Box>
                    <p>{_('save_as_draft_to_upload_images')}</p>
                  </Box>
                )}
              </Row>
            </>
          )}
          {editable && (
            <>
              <SafeArea>
                <h2>{_('shipping_address')}</h2>
              </SafeArea>
              <Box>
                <Controller
                  control={form.control}
                  name="shippingAddress.company"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('company')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.firstName"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('first_name')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.lastName"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('last_name')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.streetAndNumber"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('street_and_number')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.postalCode"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('postal_code')}
                    />
                  }
                />
                <Controller
                  control={form.control}
                  name="shippingAddress.city"
                  as={
                    <InputField
                      errors={form.errors}
                      disabled={!editable}
                      label={_('city')}
                    />
                  }
                />

                <Controller
                  control={form.control}
                  name="shippingAddress.country"
                  as={
                    <CountrySelectBox
                      label={_('country')}
                      error={form.errors.shippingAddress?.country?.message}
                      disabled={!editable}
                    />
                  }
                />
              </Box>
            </>
          )}
          {editable && (
            <>
              <Box>
                {showPaypalButtons && (
                  <div className="block-centered">
                    <p style={{ maxWidth: '400px' }}>
                      {_('payment_explanation')}
                    </p>
                    <p>
                      {_('payment_required', {
                        values: {
                          amount: orderAmount.toFixed(2).replace('.', ','),
                        },
                      })}
                    </p>
                    <p>{_('please_choose_payment_type')}</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _(
                          'by_choosing_payment_type_you_will_accept_terms_and_conditions'
                        ) as string,
                      }}
                    />

                    <PayPalButton
                      createOrder={async (data: any, actions: any) => {
                        setShowLoader(true);
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                currency_code: 'EUR',
                                value: orderAmount,
                              },
                              description: `GALVAZON® Bestellung: ${order.data?.orderNumber}`,
                              custom_id: order.data?.id,
                            },
                          ],
                        });
                      }}
                      shippingPreference="NO_SHIPPING"
                      onSuccess={onPay}
                      options={{
                        currency: 'EUR',
                        clientId: PAYPAL_CLIENT_ID,
                      }}
                    />
                  </div>
                )}
              </Box>
            </>
          )}
        </div>
      </FormProvider>
    </Page>
  );
};
