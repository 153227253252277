import React, { ComponentType, useMemo } from 'react';

interface Props {
  id?: string;
  className?: string;
}
export const SafeArea: ComponentType<Props> = ({ children, id, className }) => {
  const cName = useMemo(() => {
    const classes = ['ui', 'safe-area'];
    if (className) {
      classes.push(className);
    }
    return classes.join(' ');
  }, [className]);
  return (
    <div id={id} className={cName}>
      {children}
    </div>
  );
};
