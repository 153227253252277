import classnames from 'classnames';
import { useAuth } from 'core/auth';
import Events from 'core/events';
import React, { ComponentType, ReactElement, useEffect, useRef } from 'react';
import { LoaderOverlay } from './LoaderOverlay';
import { Logo } from './Logo';
import { Divider } from './ui/Divider';
import { SafeArea } from './ui/SafeArea';

interface Props {
  id?: string;
  title?: string;
  subTitle?: string | ReactElement;
  background?: string;
  headerContent?: ReactElement;
  subHeaderContent?: ReactElement;
  loading?: boolean;
  className?: string;
}
export const Page: ComponentType<Props> = ({
  children,
  id,
  title,
  subTitle,
  background,
  headerContent,
  subHeaderContent,
  loading,
  className,
}) => {
  const { user } = useAuth();
  const headerRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (title) {
      Events.trigger('page-change', title);
    }
  }, []);

  useEffect(() => {
    if (headerRef.current && pageRef.current) {
      const height = headerRef.current.clientHeight;

      pageRef.current.style.paddingTop = `${height}px`;
    }
  }, [headerRef.current, pageRef.current]);

  return (
    <div
      ref={pageRef}
      id={id}
      className={classnames('page', user?.type, className, {
        'with-sub-header': !!subHeaderContent,
      })}
      style={background ? { backgroundImage: `url(${background})` } : undefined}
    >
      {loading && <LoaderOverlay />}
      {title && (
        <>
          <div
            ref={headerRef}
            className={classnames('header', {
              'with-sub-header': !!subHeaderContent,
            })}
          >
            <SafeArea>
              <div className="left">{headerContent}</div>
              <Logo />
              <div className="right">
                <div className="page-title">{title}</div>
                {subTitle && <div className="page-sub-title">{subTitle}</div>}
              </div>
            </SafeArea>
            {subHeaderContent}
            <Divider withoutMargin />
          </div>
        </>
      )}
      {children}
    </div>
  );
};
