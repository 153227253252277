import { createEndpoint, useRemoteSingle } from 'core/endpoint';
import { useLocale, useTranslator } from 'core/translator';
import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedDate } from 'shared/components/FormattedDate';
import { MiniCart } from 'shared/components/MiniCart';
import { Page } from 'shared/components/Page';
import { OrderStatus } from 'shared/components/order/list/OrderStatus';
import { CoatingOrderView } from 'shared/components/order/view/coating';
import { Box, Row } from 'shared/components/ui/Box';
import { Button } from 'shared/components/ui/Button';
import '../index.scss';

export const CoatingOrder: ComponentType = () => {
  const { id } = useParams<{ id: string }>();
  const _ = useTranslator();
  const history = useHistory();
  const order = useRemoteSingle(
    createEndpoint<API.CoatingOrder>('producer/orders')
  );

  const [locale] = useLocale();

  useEffect(() => {
    if (id) {
      order.load(parseInt(id)).catch((e) => {
        alert('Keine Berechtigung.');
        history.replace('/producer/dashboard');
      });
    }
  }, [id]);

  const onShip = useCallback(() => {
    if (order.data) {
      order.patch(order.data.id, 'ship');
    }
  }, [order]);

  const pageTitle = useMemo(() => {
    let title = _('order');
    if (order.data) {
      title = `${title} #${order.data.id}`;
    }
    return title;
  }, [order, locale]);

  if (!order.data) {
    return <Page loading />;
  }

  if (order.data.kind != 'CoatingOrder') {
    return <Page>Error</Page>;
  }
  return (
    <Page
      className={'order'}
      loading={order.loading}
      title={pageTitle}
      subTitle={<OrderStatus order={order.data} type="producer" />}
    >
      <Row>
        <Box>
          <CoatingOrderView order={order.data} userType="producer" />
        </Box>
      </Row>

      {order.data.orderedAt && !order.data.shippedAt && (
        <Row>
          <Box>
            <h2>{_('offer')}</h2>
            <MiniCart
              items={[
                {
                  quantity: order.data.quantity,
                  amount: order.data.price || 0,
                },
              ]}
              withTax={order.data?.customer.address.country === 'DE'}
            />
            am <FormattedDate value={order.data.orderedAt} withTime />
            <p>{_('the_order_has_been_placed_update_shipping_state')}</p>
            <Button label={_('mark_as_shipped')} onClick={onShip} />
          </Box>
        </Row>
      )}
      {order.data.shippedAt && (
        <Row>
          <Box>
            <h2>{_('order')}</h2>
            <MiniCart
              items={[
                {
                  quantity: order.data.quantity,
                  amount: order.data.price || 0,
                },
              ]}
              withTax={order.data?.customer.address.country === 'DE'}
            />{' '}
            am <FormattedDate value={order.data.orderedAt} withTime />
            <p>{_('the_order_has_been_shipped')}</p>
          </Box>
        </Row>
      )}
    </Page>
  );
};
