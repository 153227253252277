import { useAuth } from 'core/auth';
import React, { ComponentType, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Greeter } from 'shared/components/greeter/greeter.component';

export const Logout: ComponentType = () => {
  const history = useHistory();
  const { logout } = useAuth();

  useEffect(() => {}, []);

  return (
    <>
      <Greeter.GoodBye
        onOk={() => logout().then((e) => history.push('/'))}
        onCancel={() => history.goBack()}
      />
    </>
  );
};
