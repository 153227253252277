import { frameOrderDefaultValues } from '../editor/frame/form';

const PX_PER_MM = 3;
const HOOK_WIDTH = 1;
const HIGHLIGHT_COLOR = '#000000';
const GREY_COLOR = '#666666';
const ORANGE_COLOR = 'orange';
const SAFE_BORDER = 0;

function s(mm: number) {
  return PX_PER_MM * mm;
}

function labelIt(
  value: number | string,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  rotated?: boolean
) {
  ctx.font = '20px sans-serif';
  ctx.fillStyle = 'green';
  const m = ctx.measureText(value.toString());
  ctx.save();
  ctx.translate(x, y);
  if (rotated) {
    ctx.rotate(-Math.PI / 2);
  }
  ctx.textAlign = 'center';
  ctx.fillText(value.toString(), 0, 10);
  ctx.restore();
}

function topBar(order: API.FrameOrderFormFields) {
  return order.distanceLiquidLevel + order.cathodeBarHeight;
}

function getPartY(order: API.FrameOrderFormFields, i: number) {
  const distanceVertical = order.partDistanceVertical || 0;
  const topY = topBar(order) + order.distanceCathodeBarFirstPart;
  return (
    s(topY) +
    s(order.frameHeight - topY) / 2 +
    s(distanceVertical) * i -
    (s(distanceVertical) * (order.numPartRows - 1)) / 2
  );
}

function getShadowerColor(order: API.FrameOrderFormFields) {
  return order.shadowerType === 'active' ? '#ff9090' : '#00c0ff';
}

type Size = { width: number; height: number };
export class OrderRenderer {
  private canvas?: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D | null = null;
  private order: API.FrameOrderFormFields = frameOrderDefaultValues;
  private highlight?: keyof API.FrameOrderFormFields;
  private leftPane: Size = { width: 0, height: 0 };
  private rightPane: Size = { width: 0, height: 0 };

  constructor() {}

  setCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = this.canvas.getContext('2d');

    this.draw();
  }

  update(
    order: API.FrameOrderFormFields,
    highlight?: keyof API.FrameOrderFormFields
  ) {
    this.order = order;
    this.highlight = highlight;
    this.updateSizes();
    this.draw();
  }

  updateSizes() {
    this.canvas!.height =
      s(this.order.frameHeight + s(topBar(this.order))) + s(SAFE_BORDER * 2);
    const normalWidth = s(
      this.order.frameWidth +
        Math.max(0, this.order.shadowerPositionLeft) +
        Math.max(0, this.order.shadowerPositionRight) +
        SAFE_BORDER * 2
    );
    this.canvas!.width = normalWidth + (normalWidth / 1.7) * 0.7;
    this.canvas!.style.height = `${Math.round(this.canvas!.height / 2)}px`;
    this.canvas!.style.width = `${Math.round(this.canvas!.width / 2)}px`;
    this.leftPane = {
      width: this.canvas!.width * 0.7,
      height: this.canvas!.height,
    };
    this.rightPane = {
      width: this.canvas!.width * 0.3,
      height: this.canvas!.height,
    };
  }

  draw() {
    if (!this.order || !this.ctx || !this.canvas) {
      return;
    }
    // this.ctx.scale(2, 2);
    this.ctx.font = '20px sans-serif';
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = '#ffffff';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.ctx.save();
    this.ctx.translate(s(SAFE_BORDER), s(SAFE_BORDER));
    this.drawHangingHooks(this.ctx);

    this.drawFrame(this.ctx);

    this.drawColumns(this.ctx);

    this.drawRows(this.ctx);

    this.drawShadowers(this.ctx);
    this.ctx.restore();
    this.ctx.fillStyle = GREY_COLOR;
    this.ctx.fillText('front', 10, this.canvas.height - 10);
    this.ctx.fillText(
      'side',
      this.leftPane.width + 10,
      this.canvas.height - 10
    );

    this.ctx.strokeStyle = GREY_COLOR;
    this.ctx.setLineDash([5, 15]);
    this.ctx.beginPath();
    this.ctx.moveTo(this.leftPane.width, 0);
    this.ctx.lineTo(this.leftPane.width, this.leftPane.height);
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.setLineDash([]);
  }

  drawFrame(ctx: CanvasRenderingContext2D) {
    // draw badspiegel

    labelIt('Badspiegel', ctx, 70, s(topBar(this.order) - 4));
    ctx.fillStyle = '#e6efff';
    ctx.fillRect(
      0,
      s(topBar(this.order)),
      this.leftPane.width + this.rightPane.width,
      this.leftPane.height
    );

    ctx.fillStyle =
      this.highlight === 'numPartColumns' || this.highlight === 'numPartRows'
        ? HIGHLIGHT_COLOR
        : this.getFrameColor();
    // left
    ctx.save();
    ctx.translate(s(Math.max(0, this.order.shadowerPositionLeft)), 0);
    ctx.fillRect(
      (this.leftPane.width - s(this.order.frameWidth)) / 2,
      s(topBar(this.order)),
      s(this.order.frameWidth),
      s(HOOK_WIDTH)
    );
    labelIt(
      this.order.frameWidth,
      ctx,
      this.leftPane.width / 2,
      s(topBar(this.order)) + 20
    );
    ctx.restore();
  }

  drawColumns(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle =
      this.highlight === 'partDistanceHorizontal' ||
      this.highlight === 'numPartColumns'
        ? HIGHLIGHT_COLOR
        : this.getFrameColor();
    // left
    // bottom
    ctx.save();
    ctx.translate(s(Math.max(0, this.order.shadowerPositionLeft)), 0);

    if (this.order.numPartColumns > 0) {
      ctx.fillRect(
        (this.leftPane.width - s(this.order.frameWidth)) / 2,
        s(this.order.frameHeight),
        s(this.order.frameWidth),
        s(HOOK_WIDTH)
      );
      labelIt(
        this.order.frameWidth,
        ctx,
        this.leftPane.width / 2,
        s(this.order.frameHeight) + 20
      );
    }
    const distanceHorizontal = this.order.partDistanceHorizontal || 0;
    // left
    for (let i = 0; i < this.order.numPartColumns; i++) {
      const x =
        this.leftPane.width / 2 +
        s(distanceHorizontal) * i -
        (s(distanceHorizontal) * (this.order.numPartColumns - 1)) / 2;
      ctx.fillRect(
        x,
        s(topBar(this.order)),
        s(HOOK_WIDTH),
        s(this.order.frameHeight - topBar(this.order))
      );
      /* labelIt(
        this.order.frameHeight -
          this.order.distanceLiquidLevel -
          this.order.cathodeBarHeight,
        ctx,
        x + 40,
        s(this.order.frameHeight - topBar(this.order)) / 2 +
          s(topBar(this.order)),

        true
      ); */
    }
    ctx.restore();

    // right
    if (this.order.numPartColumns > 0) {
      ctx.fillRect(
        this.leftPane.width + this.rightPane.width / 2,
        s(topBar(this.order)),
        s(HOOK_WIDTH),
        s(this.order.frameHeight - topBar(this.order))
      );
      labelIt(
        this.order.frameHeight -
          this.order.distanceLiquidLevel -
          this.order.cathodeBarHeight,
        ctx,
        this.leftPane.width + this.rightPane.width / 2 + 40,
        s(this.order.frameHeight - topBar(this.order)) / 2 +
          s(topBar(this.order)),
        true
      );
    }
  }

  drawRows(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle =
      this.highlight === 'partDistanceVertical' ||
      this.highlight === 'numPartRows'
        ? HIGHLIGHT_COLOR
        : this.getFrameColor();
    // left
    const distanceHorizontal = this.order.partDistanceHorizontal || 0;

    const height = this.order.frameHeight - topBar(this.order);
    ctx.save();
    ctx.translate(s(Math.max(0, this.order.shadowerPositionLeft)), 0);
    // left
    for (let i = 0; i < this.order.numPartColumns; i++) {
      for (let j = 0; j < this.order.numPartRows; j++) {
        const x =
          this.leftPane.width / 2 +
          s(distanceHorizontal) * i -
          (s(distanceHorizontal) * (this.order.numPartColumns - 1)) / 2;

        const y = getPartY(this.order, j);
        if (j === 0 && i == 0) {
          labelIt(
            this.order.partDistanceVertical,
            ctx,
            x + s(this.order.contactingWireLength / 2),
            y + s(this.order.partDistanceVertical) / 2,
            true
          );
        }

        if (i == 0 && j == 0) {
          labelIt(
            this.order.partDistanceHorizontal,
            ctx,
            x + s(this.order.partDistanceHorizontal / 2),
            y
          );
        }

        ctx.strokeStyle =
          this.highlight === 'contactingWireLength' ||
          this.highlight === 'contactingWireAngle' ||
          this.highlight === 'contactingWireDiameter'
            ? HIGHLIGHT_COLOR
            : '#ff00ff';
        ctx.beginPath();
        ctx.arc(x, y, s(this.order.contactingWireDiameter / 2), 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();
        if (i == 0 && j == 0) {
          labelIt(
            this.order.contactingWireDiameter,
            ctx,
            x - s(this.order.contactingWireDiameter),
            y
          );
        }
      }
    }
    ctx.restore();

    // right
    // frame
    if (this.order.numPartColumns > 0) {
      ctx.fillRect(
        this.leftPane.width + this.rightPane.width / 2,
        s(topBar(this.order)),
        s(HOOK_WIDTH),
        s(this.order.frameHeight - topBar(this.order))
      );

      // duplicate?
      /* labelIt(
        this.order.frameHeight -
          this.order.distanceLiquidLevel -
          this.order.cathodeBarHeight,
        ctx,
        this.leftPane.width + this.rightPane.width / 2 + 40,
        s(this.order.frameHeight - topBar(this.order)) / 2 +
          s(topBar(this.order)),
        true
      ); */
    }
    // parts
    ctx.strokeStyle =
      this.highlight === 'contactingWireLength' ||
      this.highlight === 'contactingWireAngle' ||
      this.highlight === 'contactingWireDiameter'
        ? HIGHLIGHT_COLOR
        : '#ff00ff';
    const radians = (Math.PI * -this.order.contactingWireAngle) / 180.0;
    for (let i = 0; i < this.order.numPartRows; i++) {
      const x = this.leftPane.width + this.rightPane.width / 2;
      const y = getPartY(this.order, i);

      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(
        x + s(this.order.contactingWireLength) * Math.cos(radians),
        y + s(this.order.contactingWireLength) * Math.sin(radians)
      );
      ctx.stroke();
      ctx.closePath();
      if (i == 0) {
        labelIt(
          this.order.contactingWireLength,
          ctx,
          x + s(this.order.contactingWireLength / 2),
          y - 20
        );
      }

      if (this.order.jiggingSide === 'two-side') {
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(
          x - s(this.order.contactingWireLength) * Math.cos(radians),
          y + s(this.order.contactingWireLength) * Math.sin(radians)
        );
        ctx.stroke();
        ctx.closePath();
        if (i == 0) {
          labelIt(
            this.order.contactingWireLength,
            ctx,
            x - s(this.order.contactingWireLength / 2),
            y - 20
          );
        }
      }
    }
  }

  drawHangingHooks(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle =
      this.highlight === 'distanceHangingHooks' ||
      this.highlight === 'numHangingHooks'
        ? HIGHLIGHT_COLOR
        : this.getFrameColor();

    const distanceHangingHooks = this.order.distanceHangingHooks || 0;
    ctx.save();
    ctx.translate(s(Math.max(0, this.order.shadowerPositionLeft)), 0);
    // left
    for (let i = 0; i < this.order.numHangingHooks; i++) {
      const x =
        this.leftPane.width / 2 +
        s(distanceHangingHooks) * i -
        (s(distanceHangingHooks) * (this.order.numHangingHooks - 1)) / 2;
      ctx.fillRect(x, 0, s(HOOK_WIDTH), s(topBar(this.order)));
      if (i > 0) {
        labelIt(
          distanceHangingHooks,
          ctx,
          x - s(distanceHangingHooks / 2),
          s(this.order.cathodeBarHeight + this.order.distanceLiquidLevel / 2)
        );
      }
      labelIt(
        this.order.distanceLiquidLevel,
        ctx,
        x + 20,
        s(this.order.cathodeBarHeight + this.order.distanceLiquidLevel / 2),
        true
      );
    }
    ctx.restore();

    // right
    ctx.fillStyle =
      this.highlight === 'cathodeBarThickness'
        ? HIGHLIGHT_COLOR
        : this.order.frameColor;
    ctx.fillRect(
      this.leftPane.width +
        this.rightPane.width / 2 -
        s(this.order.cathodeBarThickness),
      0,
      s(HOOK_WIDTH),
      s(this.order.cathodeBarHeight)
    );
    ctx.fillRect(
      this.leftPane.width +
        this.rightPane.width / 2 -
        s(this.order.cathodeBarThickness),
      0,
      s(this.order.cathodeBarThickness),
      s(HOOK_WIDTH)
    );

    ctx.fillRect(
      this.leftPane.width + this.rightPane.width / 2,
      0,
      s(HOOK_WIDTH),
      s(topBar(this.order))
    );
    labelIt(
      this.order.distanceLiquidLevel,
      ctx,
      this.leftPane.width + this.rightPane.width / 2 + 20,
      s(this.order.cathodeBarHeight + this.order.distanceLiquidLevel / 2),
      true
    );

    // cathodebar (on front view - from the side)
    ctx.save();
    ctx.translate(s(Math.max(0, this.order.shadowerPositionLeft)), 0);
    ctx.fillStyle =
      this.highlight === 'cathodeBarThickness' ||
      this.highlight === 'cathodeBarHeight'
        ? HIGHLIGHT_COLOR
        : ORANGE_COLOR;
    ctx.fillRect(
      (this.leftPane.width - s(this.order.frameWidth)) / 2,
      0,
      s(this.order.frameWidth),
      s(this.order.cathodeBarHeight)
    );
    labelIt(
      this.order.cathodeBarHeight,
      ctx,
      this.leftPane.width / 2,
      s(this.order.cathodeBarHeight / 2),
      true
    );
    ctx.restore();

    // cathodebar (cut)
    ctx.strokeStyle =
      this.highlight === 'cathodeBarThickness' ||
      this.highlight === 'cathodeBarHeight'
        ? HIGHLIGHT_COLOR
        : ORANGE_COLOR;
    ctx.beginPath();
    ctx.ellipse(
      this.leftPane.width +
        this.rightPane.width / 2 -
        s(this.order.cathodeBarThickness / 2),
      s(this.order.cathodeBarHeight / 2),
      s(this.order.cathodeBarThickness / 2),
      s(this.order.cathodeBarHeight / 2),
      0,
      0,
      2 * Math.PI
    );
    ctx.stroke();
    ctx.closePath();
    labelIt(
      this.order.cathodeBarThickness,
      ctx,
      this.leftPane.width +
        this.rightPane.width / 2 -
        s(this.order.cathodeBarThickness / 2),
      s(this.order.cathodeBarHeight / 2)
    );
    labelIt(
      this.order.cathodeBarHeight,
      ctx,
      this.leftPane.width + this.rightPane.width / 2 + 20,
      s(this.order.cathodeBarHeight / 2),
      true
    );
  }

  drawShadowers(ctx: CanvasRenderingContext2D) {
    ctx.save();
    ctx.translate(s(Math.max(0, this.order.shadowerPositionLeft)), 0);
    if (this.order.shadowerPositionTop > -1) {
      ctx.fillStyle =
        this.highlight === 'shadowerPositionTop'
          ? HIGHLIGHT_COLOR
          : getShadowerColor(this.order);

      ctx.fillRect(
        (this.leftPane.width - s(this.order.frameWidth)) / 2,
        s(topBar(this.order)) - s(this.order.shadowerPositionTop),
        s(this.order.frameWidth),
        s(HOOK_WIDTH)
      );
      labelIt(
        this.order.shadowerPositionTop,
        ctx,
        (this.leftPane.width - s(this.order.frameWidth)) / 2,
        s(topBar(this.order)) - s(this.order.shadowerPositionTop / 2),
        true
      );
    }
    if (this.order.shadowerPositionBottom > -1) {
      ctx.fillStyle =
        this.highlight === 'shadowerPositionBottom'
          ? HIGHLIGHT_COLOR
          : getShadowerColor(this.order);

      ctx.fillRect(
        (this.leftPane.width - s(this.order.frameWidth)) / 2,
        s(this.order.frameHeight) + s(this.order.shadowerPositionBottom),
        s(this.order.frameWidth),
        s(HOOK_WIDTH)
      );
      labelIt(
        this.order.shadowerPositionBottom,
        ctx,
        (this.leftPane.width - s(this.order.frameWidth)) / 2,
        s(this.order.frameHeight) + s(this.order.shadowerPositionBottom / 2),
        true
      );
    }
    if (this.order.shadowerPositionLeft > -1) {
      ctx.fillStyle =
        this.highlight === 'shadowerPositionLeft'
          ? HIGHLIGHT_COLOR
          : getShadowerColor(this.order);

      ctx.fillRect(
        (this.leftPane.width - s(this.order.frameWidth)) / 2 -
          s(this.order.shadowerPositionLeft),
        s(topBar(this.order)),
        s(HOOK_WIDTH),
        s(this.order.frameHeight - topBar(this.order))
      );
      labelIt(
        this.order.shadowerPositionLeft,
        ctx,
        (this.leftPane.width - s(this.order.frameWidth)) / 2 -
          s(this.order.shadowerPositionLeft / 2),
        s(topBar(this.order))
      );
    }
    if (this.order.shadowerPositionRight > -1) {
      ctx.fillStyle =
        this.highlight === 'shadowerPositionRight'
          ? HIGHLIGHT_COLOR
          : getShadowerColor(this.order);

      ctx.fillRect(
        (this.leftPane.width + s(this.order.frameWidth)) / 2 +
          s(this.order.shadowerPositionRight),
        s(topBar(this.order)),
        s(HOOK_WIDTH),
        s(this.order.frameHeight - topBar(this.order))
      );
      labelIt(
        this.order.shadowerPositionRight,
        ctx,
        (this.leftPane.width + s(this.order.frameWidth)) / 2 +
          s(this.order.shadowerPositionRight / 2),
        s(topBar(this.order))
      );
    }
    ctx.restore();
  }

  private getFrameColor() {
    if (
      this.order.frameColor === 'rackstar' ||
      this.order.frameColor === 'rackstar_pro' ||
      this.order.frameColor === 'rackstar_pro_plus'
    ) {
      return 'green';
    }
    return this.order.frameColor;
  }
}
