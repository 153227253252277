import { yupResolver } from '@hookform/resolvers';
import { useAuth } from 'core/auth';
import { useAlert } from 'core/modal';
import { useTranslator } from 'core/translator';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Logo } from 'shared/components/Logo';
import { Page } from 'shared/components/Page';
import { Button } from 'shared/components/ui/Button';
import { Checkbox } from 'shared/components/ui/Checkbox';
import { CountrySelectBox } from 'shared/components/ui/CountrySelectBox';
import { InputField } from 'shared/components/ui/InputField';
import { SafeArea } from 'shared/components/ui/SafeArea';
import './index.scss';
import { validationSchema } from './registrationForm';
export const Register = () => {
  const history = useHistory();
  const { register: registerUser } = useAuth();
  const [success, setSuccess] = useState(false);
  const _ = useTranslator();

  const { register, handleSubmit, watch, errors } = useForm<
    API.Customer & { password: string; repeatPassword: string }
  >({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const [modal, { alarm }] = useAlert();

  const onSubmit = useCallback(async (data: any) => {
    try {
      const result = await registerUser(data);
      setSuccess(true);
    } catch (e) {
      alarm('Fehler');
      console.log(e);
    }

    /* if (user) {
        await profileEndpoint.put(undefined, data);
        refresh();
      } */
  }, []);

  return (
    <Page id="register">
      {modal}
      <SafeArea className="centered">
        <Logo />
        {!success && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              email
              placeholder={_('email')}
              name="email"
              innerRef={register}
              error={errors.email ? errors.email.message : undefined}
            />
            <InputField
              masked
              placeholder={_('password')}
              name="password"
              innerRef={register}
              error={errors.password ? errors.password.message : undefined}
            />
            <InputField
              masked
              placeholder={_('repeat_password')}
              name="repeatPassword"
              innerRef={register}
              error={
                errors.repeatPassword
                  ? errors.repeatPassword.message
                  : undefined
              }
            />
            <InputField
              placeholder={_('company')}
              name="address.company"
              innerRef={register}
              error={
                errors.address?.company
                  ? errors.address?.company.message
                  : undefined
              }
            />
            <InputField
              placeholder={_('first_name')}
              name="address.firstName"
              innerRef={register}
              error={
                errors.address?.firstName
                  ? errors.address?.firstName.message
                  : undefined
              }
            />
            <InputField
              placeholder={_('last_name')}
              name="address.lastName"
              innerRef={register}
              error={
                errors.address?.lastName
                  ? errors.address?.lastName.message
                  : undefined
              }
            />
            <InputField
              placeholder={_('street_and_number')}
              error={
                errors.address?.streetAndNumber
                  ? errors.address?.streetAndNumber.message
                  : undefined
              }
              name="address.streetAndNumber"
              innerRef={register}
            />

            <InputField
              placeholder={_('postal_code')}
              error={
                errors.address?.postalCode
                  ? errors.address?.postalCode.message
                  : undefined
              }
              name="address.postalCode"
              innerRef={register}
            />
            <InputField
              placeholder={_('city')}
              error={
                errors.address?.city ? errors.address?.city.message : undefined
              }
              name="address.city"
              innerRef={register}
            />
            <CountrySelectBox
              label={_('country')}
              error={errors.address?.country ? 'Required' : undefined}
              name="address.country"
              innerRef={register}
            />

            <InputField
              placeholder={_('vat_number')}
              error={errors.vatNumber ? errors.vatNumber.message : undefined}
              name="vatNumber"
              innerRef={register}
            />
            <Checkbox
              innerRef={register}
              name="acceptTermsAndConditions"
              error={
                errors.acceptTermsAndConditions
                  ? errors.acceptTermsAndConditions.message
                  : undefined
              }
              label={_('accept_terms_and_conditions') as string}
            />

            <Checkbox
              innerRef={register}
              name="acceptPrivacy"
              error={
                errors.acceptPrivacy ? errors.acceptPrivacy.message : undefined
              }
              label={_('accept_privacy') as string}
            />

            <Button label={_('register')} />
          </form>
        )}
        {success && (
          <>
            <p>{_('registration_successful')}</p>
            <Button path="/auth/login" label={_('log_in_now')} />
          </>
        )}
      </SafeArea>
    </Page>
  );
};
