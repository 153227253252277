// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dEJ_ciZHAOJEysM0ET_r0{padding-top:1rem}.dEJ_ciZHAOJEysM0ET_r0 ._1xqQRjxQEMGETsbmOfnZ0Q{display:flex;flex-wrap:wrap;align-items:center}.dEJ_ciZHAOJEysM0ET_r0 ._1xqQRjxQEMGETsbmOfnZ0Q ._1vMLGAdBN3FB7Z90fiVSNS{color:#777;margin:.5rem;margin-top:0rem;margin-bottom:1rem}.dEJ_ciZHAOJEysM0ET_r0 ._1xqQRjxQEMGETsbmOfnZ0Q ._1vMLGAdBN3FB7Z90fiVSNS .w-JSAE7DkbSfGe8Jkisgu{height:1rem;font-size:.8rem}.dEJ_ciZHAOJEysM0ET_r0 ._1xqQRjxQEMGETsbmOfnZ0Q ._1vMLGAdBN3FB7Z90fiVSNS .iMe0SxY-DpZiO2zXW3SQE{display:flex;justify-content:center;align-items:center;font-size:2rem}.dEJ_ciZHAOJEysM0ET_r0 ._1xqQRjxQEMGETsbmOfnZ0Q ._1vMLGAdBN3FB7Z90fiVSNS .iMe0SxY-DpZiO2zXW3SQE .xLmfvAg4jYJvHxmk995qk{margin-left:.5rem;font-size:1rem}.dEJ_ciZHAOJEysM0ET_r0 ._1xqQRjxQEMGETsbmOfnZ0Q ._1vMLGAdBN3FB7Z90fiVSNS._1ZBn1act_eRYiDlWBGiX8L .iMe0SxY-DpZiO2zXW3SQE{color:#000}", ""]);
// Exports
exports.locals = {
	"host": "dEJ_ciZHAOJEysM0ET_r0",
	"row": "_1xqQRjxQEMGETsbmOfnZ0Q",
	"operand": "_1vMLGAdBN3FB7Z90fiVSNS",
	"label": "w-JSAE7DkbSfGe8Jkisgu",
	"value": "iMe0SxY-DpZiO2zXW3SQE",
	"unit": "xLmfvAg4jYJvHxmk995qk",
	"emphasized": "_1ZBn1act_eRYiDlWBGiX8L"
};
module.exports = exports;
