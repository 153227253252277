import { useTranslator } from 'core/translator';
import React from 'react';

import { Item } from '../Item';
interface Props<T extends API.CoatingOrder> {
  order: T;
  userType: API.UserType;
}
export function CoatingOrderView<T extends API.CoatingOrder>({
  order,
  userType,
}: Props<T>) {
  const _ = useTranslator();

  return (
    <>
      <h2>{_('order_details')}</h2>
      <table className="ui table detail">
        <tbody>
          <Item label={_('quantity')} value={order.quantity} />
          <Item
            label={_('article_description')}
            value={order.articleDescription}
          />
          <Item label={_('article_number')} value={order.articleNumber} />

          {order.shape === 'Rectangle' && (
            <Item
              label={_('frame_size')}
              value={
                <>
                  {_('frame_height')}:{' '}
                  {((order as any) as API.RectangleCoatingOrder).frameHeight} mm
                  <br />
                  {_('frame_width')}:{' '}
                  {((order as any) as API.RectangleCoatingOrder).frameWidth} mm
                </>
              }
            />
          )}
          {order.shape === 'Pipe' && (
            <Item
              label={_('frame_size')}
              value={
                <>
                  {_('frame_length')}:{' '}
                  {((order as any) as API.PipeCoatingOrder).frameLength} mm
                  <br />
                  {_('inner_diameter')}:{' '}
                  {((order as any) as API.PipeCoatingOrder).innerDiameter} mm
                  <br />
                  {_('outer_diameter')}:{' '}
                  {((order as any) as API.PipeCoatingOrder).outerDiameter} mm
                </>
              }
            />
          )}

          <Item label={_('frame_color')} value={_(order.frameColor)} />
          <Item label={_('customer_comment')} value={order.customerComment} />

          <Item
            label={_('shipping_address')}
            value={
              <>
                {order.shippingAddress.company}
                <br />
                {order.shippingAddress.firstName}{' '}
                {order.shippingAddress.lastName}
                <br />
                {order.shippingAddress.streetAndNumber}
                <br />
                {order.shippingAddress.postalCode} {order.shippingAddress.city}
                <br />
                {order.shippingAddress.country}
              </>
            }
          />
          <Item
            label={_('invoice_address')}
            value={
              <>
                {order.customer.address.company}
                <br />
                {order.customer.address.firstName}{' '}
                {order.customer.address.lastName}
                <br />
                {order.customer.address.streetAndNumber}
                <br />
                {order.customer.address.postalCode}{' '}
                {order.customer.address.city}
                <br />
                {order.customer.address.country}
              </>
            }
          />
          {order.frameImage && (
            <Item
              label={_('frame_image')}
              value={
                <img
                  src={`${BACKEND_URL}/${userType}/orders/${order.id}/file/frameImage`}
                />
              }
            />
          )}
          {order.partOrCadImage && (
            <Item
              label={_('part_or_cad_image')}
              value={
                <img
                  src={`${BACKEND_URL}/${userType}/orders/${order.id}/file/partOrCadImage`}
                />
              }
            />
          )}
          {order.contactImage && (
            <Item
              label={_('contact_image')}
              value={
                <img
                  src={`${BACKEND_URL}/${userType}/orders/${order.id}/file/contactImage`}
                />
              }
            />
          )}
        </tbody>
      </table>
    </>
  );
}
