import React, {
  cloneElement,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'shared/components/ui/Button';
import { useTranslator } from './translator';

type ModalHook = [ReactElement | null, { open: () => void; close: () => void }];

export function useModal(
  renderNode: (close: () => void) => ReactElement
): ModalHook {
  const [isVisible, setVisible] = useState(false);
  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);

  let modal = null;
  if (isVisible) {
    modal = createPortal(
      <div className="ui modal">
        <div className="dimmer" />
        {cloneElement(renderNode(close))}
      </div>,
      document.body
    );
  }

  return [
    modal,
    {
      open,
      close,
    },
  ];
}

type AlertHook = [
  ReactElement | null,
  { alarm: (message: string, title?: string) => void }
];

export function useAlert(): AlertHook {
  const [content, setContent] = useState<{ title?: string; message: string }>({
    title: undefined,
    message: '',
  });
  const _ = useTranslator();
  const [modal, { open }] = useModal((close) => (
    <div className="popup">
      <div className="alert">
        {content.title && <h2>{content.title}</h2>}
        <div dangerouslySetInnerHTML={{ __html: content.message }} />
        <div className="actions">
          <Button small onClick={close} label={_('ok')} />
        </div>
      </div>
      <div className="close-button" onClick={close} />
    </div>
  ));
  const alarm = useCallback(
    (message: string, title?: string) => {
      setContent({ title, message });
      open();
    },
    [open]
  );
  return [modal, { alarm }];
}

type ConfirmHook = [
  ReactElement | null,
  {
    confirm: (
      actions: { label: string; callback: () => void }[],
      message: string,
      title?: string
    ) => void;
  }
];

export function useConfirm(): ConfirmHook {
  const [content, setContent] = useState<{ title?: string; message: string }>({
    title: undefined,
    message: '',
  });
  const [actions, setActions] = useState<
    { label: string; callback: () => void }[]
  >([]);

  const _ = useTranslator();
  const [modal, { open }] = useModal((close) => (
    <div className="popup">
      <div className="alert">
        {content.title && <h2>{content.title}</h2>}
        {content.message}
        <div className="actions">
          {actions.map((a, index) => (
            <Button
              small
              key={`action-${index}`}
              onClick={() => {
                a.callback();
                close();
              }}
              label={a.label}
            />
          ))}
          <Button
            small
            key="action-_close"
            onClick={close}
            label={_('cancel')}
          />
        </div>
      </div>
      <div className="close-button" onClick={close} />
    </div>
  ));

  const confirm = useCallback(
    (
      actions: { label: string; callback: () => void }[],
      message: string,
      title?: string
    ) => {
      setContent({ title, message });
      setActions(actions);
      open();
    },
    [open]
  );
  return [modal, { confirm }];
}
