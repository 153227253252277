import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
interface Props {
  value?: boolean;
}
export const YesNo: ComponentType<Props> = ({ value }) => {
  const _ = useTranslator();
  if (value === undefined) {
    return <span className="ui disabled">{_('n/a')}</span>;
  }
  return <>{value ? _('yes') : _('no')}</>;
};
