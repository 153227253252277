import printImg from 'assets/img/icon-print.png';

import { useLocale, useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { CustomerDescription } from 'shared/components/CustomerDescription';
import { MiniCart } from 'shared/components/MiniCart';
import { ProducerDescription } from 'shared/components/ProducerDescription';
import { Button } from 'shared/components/ui/Button';
import { OrderStatus } from './OrderStatus';

interface Props {
  order: API.BaseOrder;
}
export const OrdersListItem: ComponentType<Props> = ({ order }) => {
  const _ = useTranslator();
  const [locale] = useLocale();

  return (
    <tr>
      <td>{order.orderNumber}</td>
      <td>{_(`order_kind.${order.kind}`)}</td>
      <td>
        <CustomerDescription customer={order.customer} />
      </td>
      <td>
        <ProducerDescription producer={order.producer} />
      </td>
      <td>
        <MiniCart
          items={[{ amount: order.price || 0, quantity: order.quantity }]}
          withTax={order.customer.address.country === 'DE'}
        />
      </td>
      <td>
        <OrderStatus order={order} />
      </td>

      <td className="actions">
        {order.kind === 'FrameOrder' && (
          <Button
            small
            target="_blank"
            link={`${BACKEND_URL}/administrator/orders/${order.id}/export?lang=${locale}`}
            icon={<img src={printImg} />}
            style={{ height: 40, width: 40 }}
          />
        )}
      </td>
    </tr>
  );
};
