import { useTranslator } from 'core/translator';
import React, { ComponentType, useCallback, useState } from 'react';
import { AmountForQuantityInput } from 'shared/components/ui/AmountForQuantityInput';
import { Box, Row } from 'shared/components/ui/Box';
import { ButtonGroup } from 'shared/components/ui/ButtonGroup';
import { FormRow } from 'shared/components/ui/FormRow';
import { NumberField } from 'shared/components/ui/NumberField';
import { TextArea } from 'shared/components/ui/TextArea';
import './index.scss';

interface Props {
  order: API.BaseOrder;
  onSubmit: (
    value: number,
    comment: string,
    paymentTarget: string,
    deliveryTime: string,
    offerValidity: string
  ) => void;
  onCancel: () => void;
}
export const MakeOffer: ComponentType<Props> = ({
  order,
  onSubmit,
  onCancel,
}) => {
  const [value, setValue] = useState(order.price ? order.price / 100 : 0);
  const [comment, setComment] = useState(
    order.producerComment ? order.producerComment : ''
  );
  const [paymentTarget, setPaymentTarget] = useState(
    order.paymentTarget !== undefined && order.paymentTarget !== null
      ? order.paymentTarget
      : ''
  );
  const [deliveryTime, setDeliveryTime] = useState(
    order.deliveryTime !== undefined && order.deliveryTime !== null
      ? order.deliveryTime
      : ''
  );
  const [offerValidity, setOfferValidity] = useState(
    order.offerValidity !== undefined && order.offerValidity !== null
      ? order.offerValidity
      : ''
  );

  const _ = useTranslator();

  const handleChange = useCallback((floatValue) => {
    setValue(floatValue);
  }, []);

  return (
    <Row>
      <Box className="make-offer-input">
        <p>
          {_('please_make_an_offer {{num}}', {
            values: { num: order.quantity },
          })}
        </p>
        <p className="ui notice">{_('all_prices_exwork')}</p>
        <AmountForQuantityInput
          value={value}
          handleChange={handleChange}
          quantity={order.quantity}
        />
        <FormRow className="wide">
          <TextArea
            label={_('comment')}
            value={comment}
            onChange={setComment}
          />
        </FormRow>
        <NumberField
          prepend={_('days')}
          label={_('payment_target')}
          value={paymentTarget.toString()}
          onChange={setPaymentTarget}
        />
        <NumberField
          prepend={_('days')}
          label={_('delivery_time')}
          value={deliveryTime.toString()}
          onChange={setDeliveryTime}
        />
        <NumberField
          prepend={_('days')}
          label={_('offer_validity')}
          value={offerValidity.toString()}
          onChange={setOfferValidity}
        />
        <ButtonGroup
          actions={[
            {
              label: _('send'),
              action: () =>
                onSubmit(
                  value * 100,
                  comment,
                  paymentTarget.toString(),
                  deliveryTime.toString(),
                  offerValidity.toString()
                ),
              className: 'ok',
            },
            {
              label: _('cancel'),
              action: onCancel,
              className: 'cancel',
            },
          ]}
        />
      </Box>
    </Row>
  );
};
