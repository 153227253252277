import { PageNotFound } from 'pages/PageNotFound';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ForgotPassword } from './ForgotPasswort';
import { Login } from './Login';
import { Logout } from './Logout';
import { Register } from './Register';
import { ResetPassword } from './ResetPassword';
import { Verification } from './Verification';

const Routes = () => {
  return (
    <Switch>
      <Route path="/auth/verify/:token">
        <Verification />
      </Route>
      <Route path="/auth/register">
        <Register />
      </Route>
      <Route path="/auth/login">
        <Login />
      </Route>
      <Route path="/auth/logout">
        <Logout />
      </Route>
      <Route path="/auth/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/auth/reset-password/:token">
        <ResetPassword />
      </Route>
      <PageNotFound />
    </Switch>
  );
};

export default Routes;
