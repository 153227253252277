import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { formatCentsCurrency, formatPercentage } from 'shared/utils';
import { useCoatingOrderPriceCalculator } from '../calculator.hook';
import styles from './formula.module.scss';
import { Operand } from './operand.component';

interface PipeFormulaProps {
  withDiscount: boolean;
  country?: string;
  length: number;
  innerDiameter: number;
  outerDiameter: number;
  quantity: number;
}

export const PipeFormula: ComponentType<PipeFormulaProps> = (props) => {
  const {
    withDiscount,
    country,
    length,
    innerDiameter,
    outerDiameter,
    quantity,
  } = props;

  const {
    totalAmount,
    fixedAmount,
    isFixedAmount,
    fixedAmountPipe,
    discount,
    totalAmountGross,
    thresholdPipeInnerDiameter,
    lateralSurface,
  } = useCoatingOrderPriceCalculator({
    length,
    innerDiameter,
    outerDiameter,
    shape: 'Pipe',
    quantity,
    withDiscount,
    country,
  });
  const _ = useTranslator();

  return (
    <>
      <div className={styles.host}>
        {!isFixedAmount && (
          <>
            <div className={styles.row}>
              <Operand
                emphasized
                label={_('lateral_surface')}
                unit={
                  <>
                    m<sup>2</sup>
                  </>
                }
                value={lateralSurface.toFixed(2).replace('.', ',')}
              />{' '}
              =
              <Operand
                label={_('frame_length')}
                unit="m"
                value={(length * 0.001).toFixed(2).replace('.', ',')}
              />{' '}
              x{' '}
              <Operand
                label={_('inner_diameter')}
                unit="m"
                value={(innerDiameter * 0.001).toFixed(2).replace('.', ',')}
              />{' '}
              x <Operand value="π" />+{' '}
              <Operand
                label={_('frame_length')}
                unit="m"
                value={(length * 0.001).toFixed(2).replace('.', ',')}
              />{' '}
              x{' '}
              <Operand
                label={_('outer_diameter')}
                unit="m"
                value={(outerDiameter * 0.001).toFixed(2).replace('.', ',')}
              />{' '}
              x <Operand value="π" />
            </div>
            <div className={styles.row}>
              <Operand
                label={_('lateral_surface')}
                unit={
                  <>
                    m<sup>2</sup>
                  </>
                }
                value={lateralSurface.toFixed(2).replace('.', ',')}
              />
              x{' '}
              <Operand
                label={_('fixed_amount')}
                value={formatCentsCurrency(fixedAmountPipe, '')}
                unit="€"
              />
              {discount !== 0 && (
                <>
                  –{' '}
                  <Operand
                    label={_('discount')}
                    value={formatPercentage(discount, false)}
                    unit="%"
                  />
                </>
              )}{' '}
              ={' '}
              <Operand
                emphasized
                label={_('total_price') + ' (net)'}
                value={formatCentsCurrency(totalAmount, '')}
                unit="€"
              />
              {totalAmount !== totalAmountGross && (
                <>
                  ≙{' '}
                  <Operand
                    emphasized
                    label={_('total_price') + ' (gross)'}
                    value={formatCentsCurrency(totalAmountGross, '')}
                    unit="€"
                  />
                </>
              )}
            </div>
          </>
        )}
        {isFixedAmount && (
          <div className={styles.row}>
            <Operand
              label={_('frame_length')}
              unit="m"
              value={(length * 0.001).toFixed(2).replace('.', ',')}
            />
            x{' '}
            <Operand
              label={_('fixed_amount')}
              value={formatCentsCurrency(fixedAmount, '')}
              unit="€"
            />{' '}
            {discount !== 0 && (
              <>
                –{' '}
                <Operand
                  label={_('discount')}
                  value={formatPercentage(discount, false)}
                  unit="%"
                />
              </>
            )}{' '}
            ={' '}
            <Operand
              emphasized
              label={_('total_price') + ' (net)'}
              value={formatCentsCurrency(totalAmount, '')}
              unit="€"
            />
            {totalAmount !== totalAmountGross && (
              <>
                ≙{' '}
                <Operand
                  emphasized
                  label={_('total_price') + ' (gross)'}
                  value={formatCentsCurrency(totalAmountGross, '')}
                  unit="€"
                />
              </>
            )}
          </div>
        )}
      </div>
      <p
        className="ui hint"
        dangerouslySetInnerHTML={{
          __html: _('fixed_amount_pipe_info', {
            values: {
              threshold: `${thresholdPipeInnerDiameter
                .toFixed(0)
                .replace('.', ',')} mm`,
              fixedPrice: formatCentsCurrency(fixedAmount),
            },
          }) as string,
        }}
      ></p>
    </>
  );
};
