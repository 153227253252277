import { MaybeTranslated } from '@translata/core';
import { useTranslator } from 'core/translator';
import React, { ComponentType, ReactElement } from 'react';
import { YesNo } from 'shared/components/ui/YesNo';
interface Props {
  label: string | MaybeTranslated;
  value?: boolean | string | number | ReactElement;
  postfix?: string;
}
export const Item: ComponentType<Props> = ({ label, value, postfix }) => {
  const _ = useTranslator();
  return (
    <tr className="item">
      <th>{label}</th>
      {value !== undefined && value !== null && typeof value === 'boolean' && (
        <td>
          <YesNo value={value} />
        </td>
      )}
      {value !== undefined &&
        value !== null &&
        typeof value !== 'boolean' &&
        typeof value !== 'object' && (
          <td
            dangerouslySetInnerHTML={{
              __html: `${value.toString().replace(/\n/g, '<br />')}${
                postfix !== undefined && postfix !== null ? ` ${postfix}` : ''
              }`,
            }}
          />
        )}
      {value !== undefined && value !== null && typeof value === 'object' && (
        <td>{value}</td>
      )}
      {(value === undefined || value === null) && (
        <td>
          <span className="ui disabled">{_('n/a')}</span>
        </td>
      )}
    </tr>
  );
};
