import React, { ComponentType, ReactText } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
interface Props {
  items: {
    label: ReactText;
    secondaryLabel?: ReactText;
    path: string;
  }[];
}
export const TabBar: ComponentType<Props> = (props) => {
  const route = useRouteMatch();

  const { items } = props;
  return (
    <div className="tab-bar ui">
      {items.map((item, index) => (
        <Link
          to={item.path}
          key={index}
          className={`tab${route.path === item.path ? ' current' : ''}`}
        >
          {item.label}
          {item.secondaryLabel !== undefined && (
            <>
              {' '}
              (<span className="label">{item.secondaryLabel}</span>)
            </>
          )}
        </Link>
      ))}
    </div>
  );
};
