import { useAuth } from 'core/auth';
import { DEBUG } from 'core/constants';
import { useAlert } from 'core/modal';
import { useTranslator } from 'core/translator';
import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Logo } from 'shared/components/Logo';
import { Page } from 'shared/components/Page';
import { Button } from 'shared/components/ui/Button';
import { InputField } from 'shared/components/ui/InputField';
import { SafeArea } from 'shared/components/ui/SafeArea';
import './index.scss';
export const Login = () => {
  const [email, setEmail] = useState(DEBUG ? 'email@philipmancarella.de' : '');
  const [password, setPassword] = useState(DEBUG ? '123' : '');
  const history = useHistory();
  const { login } = useAuth();
  const _ = useTranslator();
  const [modal, { alarm }] = useAlert();

  const onLoginClick = useCallback(async () => {
    try {
      const user = await login(email, password);
      if (user) {
        history.push(`/${user.type}`);
      } else {
        alarm(_('invalid_credentials') as string);
      }
    } catch (e) {
      alarm('Fehler');
      console.log(e);
    }
  }, [email, password, login]);

  return (
    <Page id="login">
      {modal}
      <SafeArea className="centered">
        <Logo />
        <InputField
          placeholder={_('email')}
          onKeyPress={(e) => {
            if (e.nativeEvent.keyCode === 13) {
              onLoginClick();
            }
          }}
          value={email}
          onChange={(v) => setEmail(v)}
        />
        <InputField
          masked
          placeholder={_('password')}
          value={password}
          onKeyPress={(e) => {
            if (e.nativeEvent.keyCode === 13) {
              onLoginClick();
            }
          }}
          onChange={(v) => setPassword(v)}
        />
        <Button label={_('login')} onClick={onLoginClick} />
        <p>
          <Link to="/auth/forgot-password">{_('lost_password?')}</Link>
        </p>
        <p>{_('you_dont_have_an_account?')}</p>
        <Button
          className="black"
          label={_('register_now')}
          path="/auth/register"
        />
      </SafeArea>
    </Page>
  );
};
