import * as yup from 'yup';
import { baseOrderDefaultValues, baseOrderValidationSchema } from '../form';

const shallBeANumber = yup.number().required();

const canBeANumber = yup.number().optional().positive().integer();

export const coatingOrderValidationSchema = baseOrderValidationSchema.shape({
  prePayment: yup.boolean(),
  frameHeight: shallBeANumber,
  frameWidth: shallBeANumber,
  frameColor: yup.string(),
  frameLength: shallBeANumber,
  innerDiameter: shallBeANumber,
  outerDiameter: shallBeANumber,
});

export const coatingOrderDefaultValues: API.CoatingOrderFormFields<any> = {
  ...baseOrderDefaultValues,
  prePayment: false,
  frameWidth: 1000,
  frameHeight: 1000,
  frameLength: 1000,
  innerDiameter: 150,
  outerDiameter: 155,
  frameColor: 'green' as API.FrameColor,
};
