import * as yup from 'yup';
import { baseOrderDefaultValues, baseOrderValidationSchema } from '../form';

const shallBeANumber = yup.number().required();

const canBeANumber = yup.number().optional().positive().integer();

export const frameOrderValidationSchema = baseOrderValidationSchema.shape({
  maxAppliedCurrent: shallBeANumber,
  platingProcessType: yup.string(),
  frameMaterial: yup.string(),
  numHangingHooks: shallBeANumber,
  distanceHangingHooks: canBeANumber,
  maxFrameDepth: shallBeANumber,
  distanceLiquidLevel: shallBeANumber,
  cathodeBarThickness: shallBeANumber,
  cathodeBarHeight: shallBeANumber,
  distanceCathodeBarFirstPart: shallBeANumber,
  jiggingSide: yup.string(),
  jiggingOffset: yup.boolean(),
  numPartColumns: shallBeANumber,
  numPartRows: shallBeANumber,
  partDistanceHorizontal: shallBeANumber,
  partDistanceVertical: shallBeANumber,
  contactingWireDiameter: shallBeANumber,
  contactingWireLength: shallBeANumber,
  contactingWireAngle: shallBeANumber,
  shadowerPositionTop: shallBeANumber,
  shadowerPositionRight: shallBeANumber,
  shadowerPositionBottom: shallBeANumber,
  shadowerPositionLeft: shallBeANumber,
  shadowerType: yup.string(),
  auxiliaryAnode: yup.boolean(),
  frameHeight: shallBeANumber,
  frameWidth: shallBeANumber,
  frameColor: yup.string(),
});

export const frameOrderDefaultValues: API.FrameOrderFormFields = {
  ...baseOrderDefaultValues,
  maxAppliedCurrent: 1,
  platingProcessType: 'pop',
  frameMaterial: 'aluminium',
  numHangingHooks: 2,
  distanceHangingHooks: 30,
  maxFrameDepth: 10,
  distanceLiquidLevel: 10,
  cathodeBarThickness: 10,
  cathodeBarHeight: 10,
  distanceCathodeBarFirstPart: 10,
  jiggingSide: 'one-side',
  jiggingOffset: false,
  numPartColumns: 1,
  numPartRows: 1,
  partDistanceHorizontal: 100,
  partDistanceVertical: 100,
  contactingWireDiameter: 10,
  contactingWireLength: 10,
  contactingWireAngle: 0,
  shadowerPositionTop: -1,
  shadowerPositionRight: -1,
  shadowerPositionBottom: -1,
  shadowerPositionLeft: -1,
  shadowerType: 'passive',
  auxiliaryAnode: false,
  carrier: false,
  frameHeight: 200,
  frameWidth: 200,
  frameColor: 'green' as API.FrameColor,
};
