import { PageNotFound } from 'pages/PageNotFound';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Footer } from 'shared/components/Footer';
import { Home } from './Home';
import { Imprint } from './Imprint';

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/impressum">
          <Imprint />
        </Route>
        <PageNotFound />
      </Switch>
      <Footer />
    </>
  );
};

export default Routes;
