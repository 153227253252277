import { useTranslator } from 'core/translator';
import React, { ComponentType } from 'react';
import { OrdersListItem } from './OrdersListItem';
interface Props {
  orders: API.BaseOrder[];
}
export const AdministratorOrdersList: ComponentType<Props> = ({ orders }) => {
  const _ = useTranslator();
  return (
    <table className="ui table">
      <thead>
        <tr>
          <th>{_('order_number')}</th>
          <th>Art</th>
          <th>Kunde</th>
          <th>Produzent</th>
          <th>Bestellwert</th>
          <th>{_('status')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {orders.map((o) => (
          <OrdersListItem key={o.id} order={o} />
        ))}
      </tbody>
    </table>
  );
};
