import { ErrorMessage } from '@hookform/error-message';
import React, { ComponentType } from 'react';

interface Props {
  label?: string;
  placeholder?: string;
  required?: boolean;
  value?: string | undefined;
  onChange?: (value: string) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  style?: any;
  error?: string;
  errors?: any;
  small?: boolean;
  disabled?: boolean;
  masked?: boolean;
  email?: boolean;
  innerRef?: any;
  name?: string;
}

export const InputField: ComponentType<Props> = ({
  label,
  value,
  onChange,
  onKeyPress,
  placeholder,
  required,
  style,
  error,
  errors,
  small,
  disabled,
  masked,
  email,
  innerRef,
  name,
}) => {
  return (
    <div style={style} className={`ui input-field${small ? ' small' : ''}`}>
      {label && <label>{`${label}${required ? ' *' : ''}`}</label>}
      {error && <span className="error error-message ui">{error}</span>}
      {errors && (
        <span className="ui error-message error">
          <ErrorMessage errors={errors} name={name || ''} />
        </span>
      )}
      <input
        name={name}
        ref={innerRef}
        onKeyPress={onKeyPress}
        required={required}
        disabled={disabled}
        value={value}
        placeholder={placeholder && `${placeholder}${required ? ' *' : ''}`}
        onChange={onChange ? (e) => onChange(e.target.value) : undefined}
        type={masked ? 'password' : email ? 'email' : 'text'}
      />
    </div>
  );
};
