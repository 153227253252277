import { differenceInCalendarDays, parseISO } from 'date-fns';
import { useMemo } from 'react';

type SubscriptionHook = {
  valid: boolean;
};
export function useSubscription(customer?: API.Customer): SubscriptionHook {
  const valid = useMemo(() => {
    if (customer) {
      const date = parseISO(customer.subscriptionEnd);
      const today = new Date();

      return differenceInCalendarDays(date, today) >= 0;
    }
    return false;
  }, [customer?.subscriptionEnd]);
  return {
    valid,
  };
}
